import { Fragment } from "react";
import { Filter } from "src/api/types";
import CollapsePanel from "src/components/CollapsePanel/CollapsePanel";
import FilterPriceWrapDesktop from "src/modules/ubercatalog/components/FilterPriceWrap/FilterPriceWrapDesktop";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import CheckList from "../CheckList/CheckList";
import { styles } from './CatalogFilters.styles';

interface CatalogFiltersProps {
  filters: Filter[];
}

const CatalogFilters = (props: CatalogFiltersProps) => {
  const { filters } = props;
  const localize = useLocalize();

  return (
    <div className='CatalogFilters'>
      <div data-marker='Filters'>
        <Typography variant="text3" sbs={{ mb: '16px' }}>
          {localize('general.filters')}
        </Typography>
        {filters.map((filter) => (
          <Fragment key={filter.key} >
            {filter.type === 'single' && (
              <StylishBox sbs={{ mb: '16px' }}>
                <CheckList filter={filter} />
              </StylishBox>
            )}

            {filter.type === 'range' && (
              <FilterPriceWrapDesktop filter={filter} />
            )}

            {filter.type !== 'single' && filter.type !== 'range' && (
              <div className='CatalogFilters__widget'>
                <CollapsePanel
                  label={filter.name}
                  openedByDefault={filter.is_standard}
                >
                  <CheckList
                    filter={filter}
                    hasSearch
                  />
                </CollapsePanel>
            </div>
            )}
            </Fragment>

        ))}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default CatalogFilters;
