import { RetailChain } from "src/api/types";

interface ChainsTranslationsMap {
  [key: string]: string;
}

export const getChainsTranslationMap = (chains: RetailChain[]) => chains.reduce<ChainsTranslationsMap>((acc, i) => ({
  [i.id]: i.name,
  ...acc,
}), {});
