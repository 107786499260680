import { LocalizeFunc } from 'src/services/localize/useLocalizeService';

export type RangeNumbers = [number, number];

export interface GetPriceAndCurrencyTextParams {
  price: RangeNumbers;
  isDesktop?: boolean;
  localize: LocalizeFunc;
  currency: string;
}

const getPriceAndCurrencyText = (params: GetPriceAndCurrencyTextParams): string => {
  const { price, isDesktop = true, localize, currency } = params;

  const min = price[0] / 100;
  const max = price[1] / 100;

  if (isDesktop) {
    const currencyText = localize(`general.currency.${currency}`);

    return `${min} ${currencyText} - ${max} ${currencyText}`;
  }

  return `${min} - ${max}`;
};

export default getPriceAndCurrencyText;
