import { Category } from "../../api/types";

export default function findCategoryObject(categories: Category[], categoryId: string | null): Category | null {
  let result: Category | null = null;

  categories.forEach((obj) => {
    let foundCategory: Category | null = null;

    if (obj.id === categoryId) {
      foundCategory = obj;
    } else if (obj.children && obj.children.length > 0) {
      foundCategory = findCategoryObject(
        obj.children,
        categoryId,
      );
    }
    if (foundCategory) {result = foundCategory;}
  });

  return result;
}
