import cn from 'classnames';
import { Fragment } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import getStyles from './LoadingBarIndicator.styles';

interface Props {
  isUnderContainer?: boolean;
}

function LoadingBarIndicator(props: Props) {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Fragment>
      <div
        data-testid='LoadingBarIndicator'
        data-marker='Loading Bar Indicator'
        className={cn(
          'LoadingBarIndicator', {
          'LoadingBarIndicator_isUnderContainer': props.isUnderContainer,
        })}
      />
      <style jsx>{styles}</style>
    </Fragment>
  );
}

export default LoadingBarIndicator;
