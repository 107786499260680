import { HEADER_HEIGHT } from 'src/modules/header/components/Header/Header.styles';
import { phone } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .CatalogFiltersMobile {
      position: fixed;
      top: ${HEADER_HEIGHT}px;
      left: 0;
      width: 100%;
      z-index: 7;
    }

    .CatalogFiltersMobile__open {
      z-index: 9;
    }

    .CatalogFiltersMobile__wrap {
      position: fixed;
      z-index: 9999;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${theme.base.color.white};
      display: flex;
      flex-direction: column;
      height: 100vh;
      height: 100dvh;
      width: 496px;
    }
    .CatalogFiltersMobile__content {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    .CatalogFiltersMobile__overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9998;
      background: ${theme.base.color.black};
      opacity: 0.3;
      height: 100vh;
      height: 100dvh;
    }

    @media ${phone} {
      .CatalogFiltersMobile {
        top: 57px;
      }
      .CatalogFiltersMobile__wrap {
        width: 100%;
      }
    }
  `;
};
