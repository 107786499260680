import { useEffect, useRef, useState } from "react";
import Button from 'src/ui-kit/Button';
import useLocalize from "src/services/localize/useLocalize";
import { getStyles } from "./Truncate.styles";

export interface Props {
  content: string;
  lines: number;
}

const Truncate = ({ content, lines }: Props) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const withTruncating = isTruncated && isOverflowing;
  const { styles, className } = getStyles({
    withTruncating,
    lines,
  });

  const localize = useLocalize();

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const element = contentRef.current;
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
        const maxHeight = lineHeight * lines;
        setIsOverflowing(element.scrollHeight > maxHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [content, lines]);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const buttonKey = isTruncated ? 'show-more' : 'show-less';

  return (
    <div className={`Truncate ${className}`} >
      <div
        className={`Truncate__content ${className}`}
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {isOverflowing && (
        <Button
          onClick={toggleTruncate}
          variant="ghost"
          dataMarker={buttonKey}
          width='auto'
        >
          {localize(`products.${buttonKey}`)}
        </Button>
      )}
      {styles}
    </div>
  );
};

export default Truncate;
