import { MouseEvent } from 'react';
import getStyles from './ProductBtnZoomImage.styles';
import IconZoom from 'src/components/Icons/IconZoom';


interface Props {
  dataMarker?: string;
  onClick(event: MouseEvent): void;
}

const ProductBtnZoomImage = (props: Props) => {
  const { onClick, dataMarker } = props;
  const styles = getStyles();

  return (
    <div
      className="ProductBtnZoomImage"
      data-marker={dataMarker}
      onClick={onClick}
    >
      <IconZoom />
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductBtnZoomImage;
