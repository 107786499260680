import { ParsedUrlQuery } from "querystring";
import { getCategoriesUrl } from "./getCategoriesUrl";
interface Params {
  query: ParsedUrlQuery;
  categoryId: string;
}

export const getCategoryHrefWithSort = ({ query, categoryId }: Params) => {
  const { sort, lang } = query;
  const sortQuery = sort ? `?sort=${sort}` : '';

  return getCategoriesUrl({
    lang: String(lang),
    categoryId,
    query: sortQuery,
  });
};
