import { getCategoriesUrl } from "src/modules/ubercatalog";
import { PathHandler } from "../getRedirectPath";
import getSegmentInfo from "../helpers/getSegmentInfo";

export const handleOldUberSearch: PathHandler = async (ctx) => {
  const {
    path,
  } = ctx;

  const { segments, langSegment, params } = getSegmentInfo(path);
  const isOldUberPage = segments.length === 2
    && segments[1] === 'products'; //for old urls /products and /products?q=

  const isOldUberSearch = segments.length === 3
    && segments[1] === 'products'
    && segments[2].includes('search_query'); // for old urls /products/search_query?q=

  const withSearchPagesRedirect = isOldUberPage || isOldUberSearch;
  if (withSearchPagesRedirect) {
    const urlParams = new URLSearchParams(params);
    const categoryIdParamKey = 'group_id_';

    if (urlParams.has(categoryIdParamKey)) { //for old urls /products?q={query}&group_id_={categoryId}
      const categoryId = urlParams.get(categoryIdParamKey)!;

      return {
        path: getCategoriesUrl({
          lang: langSegment,
          categoryId: `${categoryId}-zakaz`,
        }),
      };
    }

    return {
      path: `/${langSegment}`,
    };
  }

  return {
    path,
  };
};

