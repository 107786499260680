import { RetailChain, QueryString } from "src/api/types";

interface getRetailInfoLinkUrlParams {
  language: string;
  retailChain: RetailChain;
  withDeliveryData: boolean;
  deliveryAsQueryStringForLink: QueryString;
  specificRoute?: string;
}


const getRetailInfoLinkUrl = (params: getRetailInfoLinkUrlParams) => {
  const {
    language,
    retailChain,
    withDeliveryData,
    specificRoute = '',
    deliveryAsQueryStringForLink,
  } = params;

  const basePath = `https://${retailChain.hostname}/${language}/${specificRoute}`;

  if (withDeliveryData) {
    return `${basePath}${deliveryAsQueryStringForLink}`;
  }

  return basePath;
};

export default getRetailInfoLinkUrl;
