import { NextRouter } from "next/router";
import makeSearchString from "src/utils/system/makeSearchString";

const popularityOption: SortOption = {
  id: 'popularity_desc',
  translation_key: 'general.sort.popular-desc',
  value: 'popularity_desc',
};

const relevance_desc: SortOption = {
  id: 'relevance_desc',
  translation_key: 'general.sort.relevance_desc',
  value: 'relevance_desc',
};

export const baseSortList: SortOption[] = [
  {
    id: 'price_asc',
    translation_key: 'general.sort.price-asc',
    value: 'price_asc',
  },
  {
    id: 'price_desc',
    translation_key: 'general.sort.price-desc',
    value: 'price_desc',
  },
  {
    id: 'alphabetic_asc',
    translation_key: 'alphabetic_asc',
    value: 'alphabetic_asc',
  },
  {
    id: 'alphabetic_desc',
    translation_key: 'alphabetic_desc',
    value: 'alphabetic_desc',
  },
];

export const sortOrderList: SortOption[] = [
  {
    ...popularityOption,
    default: true,
  },
  ...baseSortList,
];

export const searchResultsSortOrderList: SortOption[] = [
  {
    ...relevance_desc,
    default: true,
  },
  popularityOption,
  ...baseSortList,
];

export type SortOption = {
  id: string;
  translation_key: string;
  value: string;
  default?: boolean;
}

export function getDefaultSort(sortList: SortOption[]) {
  const defaultArray = sortList.find(item => item.default);
  return defaultArray ? defaultArray.value : sortList[0].value;
}

export function getSortList(pathname = '') {
  return /\/search/.test(pathname)
    ? searchResultsSortOrderList
    : sortOrderList;
}

export function navigateToSort(router: NextRouter, value: string) {
  const { pathname, asPath, push, query } = router;

  const path = asPath.split('?')[0];
  const newQuery = {...query};

  // clear pagination if we change sorting
  if (query.sort !== value && query.page) {
    delete newQuery.page;
  }

  newQuery.sort = value;
  const searchParams = makeSearchString(newQuery);

  push({
    pathname,
    query: newQuery,
  }, `${path}${searchParams}`);
}


type SortQueryType = string | string[] | undefined;

export const getActiveSortValue = (sort: SortQueryType, cookieSort?: string) => {
  const sortList = getSortList();
  const defaultSort = getDefaultSort(sortList);
  const querySort  = sort && !Array.isArray(sort) ? sort : undefined;

  return querySort || cookieSort || defaultSort;
};
