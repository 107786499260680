import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import { getStyles } from './ProductTilePlaceholder.styles';
import { useTheme } from 'src/ui-kit/theme';

interface Props {
  repeat: number;
}

const ProductTilePlaceholder = ({ repeat }: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const rectColor = theme.base.color.gray100;

  return (
    <div className="ProductTilePlaceholder">
      {[...Array(repeat)].map((_, i) => (
        <div key={i} className='ProductTilePlaceholder__item'>
          <div className='ProductTilePlaceholder__image'>
            <RectShape color={rectColor}/>
          </div>
          <div className='ProductTilePlaceholder__button'>
            <RectShape color={rectColor}/>
          </div>
          <div className='ProductTilePlaceholder__details'>
            <TextBlock rows={3} color={rectColor} lineSpacing={'6px'} />
          </div>
        </div>
      ))}

      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductTilePlaceholder;
