import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .RetailsInfoSkeleton__item {
      height: 70px;
    }
  `;
};

export default getStyles;
