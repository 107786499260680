import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import { getStyles } from './Badge.styles';

export type BadgeType = 'bundle';

export interface BadgeProps {
  type: BadgeType;
  straightLeft?: boolean;
  text?: string;
  dataMarkerValue?: string;
}

const Badge = (props: BadgeProps) => {
  const {
    type,
    straightLeft,
    dataMarkerValue,
    text,
  } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div
      className={cn(
        'Badge',
        `Badge_${type}`,
        {
          'Badge_straightLeft': straightLeft,
        })}
      data-testid='productBadge'
      data-marker={dataMarkerValue}
    >
      <span className='Badge__text'>{text}</span>
      <style jsx>{styles}</style>
    </div>
  );
};

export default Badge;
