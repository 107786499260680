import { ParsedUrlQuery } from 'querystring';
import { Filter, Option } from 'src/api/types';

// Тип для активного фильтра
export type ActiveFilter = {
  type: string;
  option?: Option;
  price?: string;
};

// Функция для поиска и возврата активного фильтра по ключу фильтра и значению запроса
const findFilter = (filterKey: string, query: string, filters: Filter[]): ActiveFilter | null => {
  // Находим группу фильтров по ключу
  const filterGroup = filters.find(filter => filter.key === filterKey);

  // Если группа фильтров не найдена или группа не имеет опций, возвращаем null
  if (!filterGroup || (filterGroup.type !== 'range' && filterGroup.options.length <= 0)) {
    return null;
  }

  // Преобразуем query в строку, если это число, и ищем соответствующую опцию
  const option = filterGroup.type !== 'range' && filterGroup.options.find(o => `${o.value}` === query);

  // Если опция не найдена, возвращаем null
  if (!option) {
    return null;
  }

  // Возвращаем активный фильтр с найденной опцией
  return {
    type: filterKey,
    option,
  };
};

// Функция для получения объектов активных фильтров из query и массива фильтров
const getActiveFiltersObjects = (query: ParsedUrlQuery, filters: Filter[]): ActiveFilter[] => {
  // Получаем ключи из query
  const queryKeys = Object.keys(query);

  // Используем flatMap для создания массива активных фильтров
  const activeFilters = queryKeys.flatMap(key => {
    const value = query[key] || '';

    // Если ключ равен 'price', создаем и возвращаем фильтр цены
    if (key === 'price') {
      const priceFilter = {
        type: 'price',
        price: value,
      } as ActiveFilter;

      return priceFilter;
    }

    // Если значение является массивом, обрабатываем каждый элемент массива
    if (Array.isArray(value)) {
      return value.map(v => findFilter(key, v, filters)).filter((filter): filter is ActiveFilter => filter !== null);
    }

    // Для одиночных значений ищем соответствующий фильтр и возвращаем его
    const filter = findFilter(key, value as string, filters);
    return filter ? [filter] : [];
  });

  // Возвращаем массив активных фильтров
  return activeFilters;
};

export default getActiveFiltersObjects;
