interface Params {
  categoryId: string;
  lang: string;
  query?: string;
}

export const getCategoriesUrl = (params: Params) => {
  const {
    categoryId,
    lang,
    query = '',
  } = params;
  return `/${lang}/categories/${categoryId}/${query}`;
};
