import { Fragment } from 'react';
import { useLocalize } from '../../../../services/localize/useLocalize';
import pluralize from '../../../../utils/products/pluralize';

interface Props {
  number: number;
}

const GoodsNumber = (props: Props) => {
  const { number } = props;
  const localize = useLocalize();
  const pluralization = pluralize(number, localize('general.items-plural'));

  return (
    <Fragment>
      {`${number} ${pluralization}`}
    </Fragment>
  );
};

export default GoodsNumber;
