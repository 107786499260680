import { desktop, phone, phone_tablet, tablet } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    @media ${desktop} {
      .CheckListItem:not(:last-child) {
        padding-bottom: 16px;
      }
    }
    @media ${phone_tablet} {
      .CheckListItem {
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${theme.base.color.gray200};
      }
      .CheckListItem_fullWidth {
        width: 100%;
      }
    }

    @media ${tablet} {
      .CheckListItem {
        padding: 16px 24px;
      }
    }

    @media ${phone} {
      .CheckListItem {
        padding: 10px 16px;
      }
    }
  `;
};
