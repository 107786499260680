import dynamic from 'next/dynamic';
import { CSSProperties, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useTheme } from 'src/ui-kit/theme';
import LoadingBarIndicator from '../LoadingBarIndicator/LoadingBarIndicator';
import { disableBodyScroll, enableBodyScroll } from './scrollLock';

const GeneralModalCloseButton = dynamic(
  () => import('./GeneralModalCloseButton/GeneralModalCloseButton'),
  { ssr: false },
);

ReactModal.defaultStyles.content = {};


export interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  contentStyles?: CSSProperties;
  overlayStyles?: CSSProperties;
  withCloseButton?: boolean;
  isFetching?: boolean;
}

export default function GeneralModal(props: Props) {
  const {
    children,
    onClose,
    isOpen,
    overlayStyles,
    contentStyles,
    withCloseButton,
    isFetching,
  } = props;

  const theme = useTheme();

  const defaultStyles: {
    overlay: CSSProperties;
    content: CSSProperties;
  } = {
    overlay: {
      background: 'rgba(51,51,51,0.45)',
      zIndex: theme.base.zIndex.modalOverlay,
      display: 'flex',
      overflowY: 'auto',
      textAlign: 'center',
    },
    content: {
      border: 'none',
      background: 'none',
      borderRadius: 0,
      padding: 0,
      outline: 'none',
      margin: '50px auto auto',
      display: 'inline-block',
      position: 'relative',
      textAlign: 'initial',
      verticalAlign: 'middle',
    },
  };

  const style: ReactModal.Props['style'] = {
    overlay: {
      ...(overlayStyles || defaultStyles.overlay),
      lineHeight: '100vh',
    },
    content: {
      ...(contentStyles || defaultStyles.content),
      lineHeight: 'initial',
    },
  };

  if (isOpen && typeof window === 'undefined') {
    throw new Error('Do not render modals on SSR.');
  }

  const appElementProps = process.env.NODE_ENV !== 'test'
    ? { appElement: getAppElement() }
    : { ariaHideApp: false };

  return (
    <ReactModal
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      isOpen={isOpen}
      style={style}
      bodyOpenClassName='Modal_opened'
      onAfterOpen={disableBodyScroll}
      onAfterClose={enableBodyScroll}
      {...appElementProps}
    >
      {children}

      {withCloseButton && <GeneralModalCloseButton onClick={onClose} />}

      {isFetching && <div className='Modal__loader'><LoadingBarIndicator /></div>}

      <style jsx>{`
        .Modal__loader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: ${theme.base.color.white};
          opacity: 0.8;
          z-index: 1;
        }
      `}</style>
    </ReactModal>
  );
}

function getAppElement() {
  if (typeof window !== 'undefined') {
    return document.getElementById('__next') || undefined;
  }
}
