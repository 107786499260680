import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { Filter } from 'src/api/types';
import useLocalize from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import useUpdateQueryParameter from '../../hooks/useUpdateQueryParameter';
import { ActiveFilter } from '../../utils/getActiveFiltersObjects';
import CatalogFiltersMobileCategories from '../CatalogFiltersMobileCategories/CatalogFiltersMobileCategories';
import CatalogFiltersMobileOptions from '../CatalogFiltersMobileOptions/CatalogFiltersMobileOptions';
import CatalogFiltersMobilePrice from '../CatalogFiltersMobilePrice/CatalogFiltersMobilePrice';
import ContentSortModal from '../ContentSortModal/ContentSortModal';
import { getStyles } from './CatalogFiltersMobile.styles';
import CatalogFiltersMobileFooter from './CatalogFiltersMobileFooter';
import useCatalogFiltersMobile from './useCatalogFiltersMobile';
import useCatalogSortMobile from './useCatalogSortMobile';

const CatalogSortPanel = dynamic(() => import('../CatalogSortPanel/CatalogSortPanel'), { ssr: false });

export interface CatalogFiltersMobileProps {
  filters: Filter[];
  activeFilters: ActiveFilter[];
}

export const CatalogFiltersMobile = (props: CatalogFiltersMobileProps) => {
  const { filters, activeFilters } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  const activeFiltersExists = activeFilters.length > 0;

  const {
    isFilterMenuOpened,
    selectedFilterType,
    setSelectedFilterType,
    openFilter,
    closeFilter,
    closeCategory,
  } = useCatalogFiltersMobile();

  const {
    selectedSortItem,
    openSorting,
    closeSorting,
    isSortingOpened,
  } = useCatalogSortMobile();

  const {
    updateQueryParameter,
    clearAllQueryParameters,
    selectedQueryFilters,
  } = useUpdateQueryParameter();

  return (
    <div
      className={classNames('CatalogFiltersMobile', {
        CatalogFiltersMobile__open: isFilterMenuOpened,
      })}
    >
      <CatalogSortPanel
        buttons={[
          {
            icon: 'filters',
            label: localize('general.filters'),
            onClick: openFilter,
            dataMarker: "Filters Button",
          },
          {
            label: localize(selectedSortItem.translation_key),
            onClick: openSorting,
            dataMarker: "Sorting Button",
          },
        ]}
      />

      <ContentSortModal
        isOpen={isSortingOpened}
        onClose={closeSorting}
      />

      {isFilterMenuOpened && (
        <Fragment>
          <div
            className='CatalogFiltersMobile__wrap'
            data-marker-popup='Mobile filters'
          >
            {selectedFilterType?.type === 'range' ? (
              <CatalogFiltersMobilePrice
                filter={selectedFilterType}
                onBackClick={closeCategory}
              />
            ) : (
              <Fragment>
                {selectedFilterType
                  ? (
                    <CatalogFiltersMobileOptions
                      activeFilters={activeFilters}
                      selectedFilterType={selectedFilterType}
                      closeCategory={closeCategory}
                      deleteFilters={updateQueryParameter}
                    />
                  )
                  : (
                    <CatalogFiltersMobileCategories
                      filters={filters}
                      activeFilters={activeFilters}
                      openCategory={setSelectedFilterType}
                      handleFilter={updateQueryParameter}
                      deleteFilters={clearAllQueryParameters}
                      selectedQueryFilters={selectedQueryFilters}
                    />
                  )
                }
                <CatalogFiltersMobileFooter
                  isChanged={activeFiltersExists}
                  onClick={closeFilter}
                />
              </Fragment>
            )}
          </div>
          <div
            className='CatalogFiltersMobile__overlay'
            onClick={closeFilter}
          />
        </Fragment>
      )}

    <style jsx>{styles}</style>
  </div>
  );
};

export default CatalogFiltersMobile;
