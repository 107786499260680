import { BreadcrumbsItem } from "src/modules/ubercatalog/components/Breadcrumbs/Breadcrumbs";

export default function getBreadcrumbsMicroMarking(
  breadcrumbs: BreadcrumbsItem[],
  baseURL: string,
) {
  const itemListElement = breadcrumbs.map((breadcrumb, index) => {
    const item = breadcrumb.url ? { item: baseURL + breadcrumb.url } : {};

    return {
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.title,
      ...item,
    };
  });

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
}
