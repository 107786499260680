import { ExperimentConfig } from "../types";
import { getVariantFromCookies, setVariantToCookies } from "./cookies";
import { DEFAULT_EXPERIMENT_INDEX, getRandomFromPercentages } from "./getRandomFromPercentages";

export const getExperimentVariant = (experiment: ExperimentConfig, id: string): number => {
  const {
    endDate,
    distribution,
  } = experiment;

  const currentDate = new Date();
  const expireDate = new Date(endDate);

  const isExpired = currentDate > expireDate;

  if(isExpired) {
    return DEFAULT_EXPERIMENT_INDEX;
  }
  const variantFromCookies = getVariantFromCookies(id);

  if(variantFromCookies) {
    return Number(variantFromCookies);
  }

  const randomVariant = getRandomFromPercentages(distribution);
  setVariantToCookies(id, randomVariant);

  return randomVariant;
};
