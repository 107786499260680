import css from 'styled-jsx/css';
import { phone, tablet } from '../../../../styles/media';

const getStyles = () => {
  return css`
  .ProductImagesGallery {
    position: relative;
    width: 100%;
    max-width: 272px;
  }

  .ProductImagesGallery__slide {
    position: relative;
    flex-shrink: 0;
    width: 100%;
  }

  .ProductImagesGallery__thumbs {
    height: 72px;
    margin-top: 12px;
  }

  .ProductImagesGallery__bullets {
    margin-top: 10px;
  }

  .ProductImagesGallery__imageContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
  }


  .ProductImagesGallery__imageContainer {
    width: 252px;
    height: 252px;
  }

  .ProductImagesGallery__noZoomImage {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  .ProductImagesGallery__defaultImage {
    max-width: 100%;
    max-height: 100%;
  }

  @media ${tablet} {
    .ProductImagesGallery {
      max-width: 344px;
    }
    .ProductImagesGallery__imageContainer {
      width: 344px;
      height: 344px;
    }
  }

  @media ${phone} {
    .ProductImagesGallery {
      max-width: 328px;
      margin: 0 auto;
    }
    .ProductImagesGallery__imageContainer {
      width: 328px;
      height: 328px;
      max-width: 100%;
    }
    .ProductImagesGallery__bullets {
      margin-bottom: 15px;
    }
  }
  `;
};

export default getStyles;
