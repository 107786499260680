import { useRouter } from "next/router";
import { getCatalogSearchProducts } from "src/api/getCatalogSearchProducts";
import { useCategorySorting } from "src/modules/filters";
import { SearchResponseDto } from "src/modules/search/hooks/useSearch";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import { BreadcrumbsProps } from "../components/Breadcrumbs/Breadcrumbs";
import { ProductBoxProps } from "../components/ProductBox/ProductBox";
import useActiveFilters from "./useActiveFilters";
import useLoadMoreAndPagination from "./useLoadMoreAndPagination";
import useLoadingServerData from "./useLoadingServerData";

const useSearchPageProps = (searchData: SearchResponseDto) => {
  const { results: products, count: productsCount, filters, count_available: countAvailable, fuzzy } = searchData;

  const lang = useLang();
  const localize = useLocalize();
  const categories = useCategories();
  const sortingProps = useCategorySorting();
  const { query } = useRouter();
  const loading = useLoadingServerData();

  const { activeFilters } = useActiveFilters(filters);

  const loadMoreAndPaginationProps = useLoadMoreAndPagination({
    products,
    productsCount,
    sort: sortingProps.selectedSortItem.value,
    fetchDataHandler: getCatalogSearchProducts,
    isFuzzy: fuzzy,
  });

  const breadcrumbsProps: BreadcrumbsProps = {
    items: [
      { url: `/${lang}/`, title: localize('pages_main'), id: 'main' },
      { title: localize('search'), id: 'search' },
    ],
  };

  const productBoxProps: ProductBoxProps = {
    countAvailable,
    activeFilters,
    sortingProps,
    loadMoreAndPaginationProps,
  };

  const searchString = typeof query.q === 'string' ? query.q : '';
  const hasActiveFilters = activeFilters.filter(i => i.type !== 'q').length > 0;
  const isVisibleEmptySearchState = !hasActiveFilters && productsCount === 0;
  const isVisibleEmptyFilterState = hasActiveFilters && productsCount === 0;

  return {
    categories,
    breadcrumbsProps,
    productBoxProps,
    searchString,
    loading,
    isVisibleEmptySearchState,
    isVisibleEmptyFilterState,
  };
};

export default useSearchPageProps;
