import { HEADER_HEIGHT } from 'src/modules/header/components/Header/Header.styles';
import { phone, tablet } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .CatalogPageLayout {
      background: ${theme.base.color.gray100};
      padding-top: ${HEADER_HEIGHT}px;
    }

    .CatalogPageLayout__container {
      margin: 0 auto;
      max-width: 1200px;
      padding: 32px 32px 0;
    }

    @media ${tablet} {
      .CatalogPageLayout_withFilters {
        padding-top: 112px;
      }
    }

    @media ${phone} {
      .CatalogPageLayout {
        padding-top: 57px;
      }

      .CatalogPageLayout__container {
        padding-left: 16px;
        padding-right: 16px;
      }

      .CatalogPageLayout_withFilters {
        padding-top: 105px;
      }
    }
  `;
};
