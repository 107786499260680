import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .Spinner {
      animation-name: rotation;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-duration: 0.8s;
      animation-timing-function: linear;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg)
      }

      100% {
        transform: rotate(360deg)
      }
    }
  `;
};

export default getStyles;
