import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .ContentSortDropdown {
      position: relative;
      z-index: 3;
    }

    .ContentSortDropdown__list {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 30px;
      right: 0;
      width: 277px;
      background: ${theme.base.color.white};
      border: ${theme.base.border.v1} ${theme.base.color.gray200};
      z-index: 2;
      box-shadow: ${theme.base.boxShadow.v1};
    }

    .ContentSortDropdown__list::before,
    .ContentSortDropdown__list::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }

    .ContentSortDropdown__list::before {
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent ${theme.base.color.gray200} transparent;
      top: -8px;
      right: 20px;
    }

    .ContentSortDropdown__list::after {
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent ${theme.base.color.white} transparent;
      top: -7px;
      right: 21px;
    }

    .ContentSortDropdown__list_withLeftAlign {
      left: 0;
      right: auto;
    }

    .ContentSortDropdown__list_expanded {
      display: block;
    }

    .ContentSortDropdown__item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ContentSortDropdown__item_active {
      padding-right: 44px;
    }

    .ContentSortDropdown__line {
      display: flex;
      font-size: 13px;
      line-height: 15px;
    }

    .ContentSortDropdown__label {
      margin-right: 2px;
      color: ${theme.base.color.gray600};
    }

    .ContentSortDropdown__selected {
      cursor: pointer;
    }
  `;
};
