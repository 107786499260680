import Link from 'next/link';
import GeneralPagesLayout from 'src/layouts/GeneralPagesLayout';
import { useTheme } from 'src/ui-kit/theme/UseTheme';
import useLang from '../../services/language/useLang';
import { useLocalize } from '../../services/localize/useLocalize';
import getStyles from './Error.styles';

const NotFoundPage = () => {
  const lang = useLang();
  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <GeneralPagesLayout>
      <div className="Error">
        <div className="Error__imageBox">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="Error__image" src="/static/images/404.png" alt="404" />
        </div>
        <div className="Error__textBox">
          <div className="Error__number">404</div>
          <div className="Error__title">{localize('whoops_page_does_not_exist')}</div>
          <Link
            href={`/${lang}/`}
            legacyBehavior
          >
            <a className="Error__button">
              {localize('to_the_main')}
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{styles}</style>
    </GeneralPagesLayout>
  );
};
export default NotFoundPage;
