import { tablet } from "src/styles/media";
import css from "styled-jsx/css";

export const getStyles = () => {
  return css`
    .ProductPage {
      padding-bottom: 50px;
    }
    @media ${tablet} {
      .ProductPage {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  `;
};
