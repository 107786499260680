import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import typography from '../../../../styles/typography';

const getStyles = (theme: Theme) => {
  return css`
    .PricesRange {
      font-size: 20px;
      line-height: 24px;
      color: ${theme.base.color.dark};
      font-weight: 600;
    }

    .PricesRange_small {
      ${typography.text}
      font-weight: 600;
    }

    .PricesRange__currency {
      ${typography.text}
      font-weight: 600;
      padding-left: 4px;
    }
 `;
};

export default getStyles;
