import { phone, tablet } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import typography from '../../styles/typography';

const getStyles = (theme: Theme) => {
  return css`
  .GeneralDrawerModalLayout {
    position: relative;
    width: 812px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${theme.base.color.gray100};
    text-align: left;
    padding: 34px 0 0;
  }
  .GeneralDrawerModalLayout__header {
    min-height: 28px;
    padding: 0 32px;
    margin-bottom: 26px;
  }
  .GeneralDrawerModalLayout__title {
    ${typography.h2}
    padding-right: 60px;
  }
  .GeneralDrawerModalLayout__buttonClose {
    position: absolute;
    top: 30px;
    right: 24px;
    width: 40px;
    height: 40px;
    padding: 12px 0;
    margin-left: auto;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
  }
  .GeneralDrawerModalLayout__content {
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
  }

  @media ${ tablet } {
    .GeneralDrawerModalLayout {
      width: 768px;
    }
  }

  @media ${ phone } {
    .GeneralDrawerModalLayout {
      width: 100vw;
      padding: 18px 0 12px;
    }

    .GeneralDrawerModalLayout__header {
      padding: 0 16px;
    }

    .GeneralDrawerModalLayout__buttonClose {
      top: 10px;
      right: 8px;
    }

    .GeneralDrawerModalLayout__title {
      ${typography.h3};
    }
  }
  `;
};

export default getStyles;
