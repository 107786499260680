import { getProductDetailsData } from "src/api/getProductDetailsData";
import { PathHandler } from "../getRedirectPath";
import getSegmentInfo from "../helpers/getSegmentInfo";
import { getProductUrl } from "src/modules/ubercatalog";

export const handleOldUberProduct: PathHandler = async (ctx) => {
  const {
    path,
  } = ctx;

  const { segments, langSegment } = getSegmentInfo(path);

  const isOldUberProductPage = segments.length === 4
    && ( segments[1] === 'products' //for old urls /products/{ean}/{slug}
      || segments[1] === 'store'); //for old urls /store/{ean}/{slug}
  const isOldUberProductPageWithCategory = segments.length === 5
    && segments[1] === 'products'; //for old urls /products/{category}/{ean}}/{slug}

  const withProductPageRedirect = isOldUberProductPage || isOldUberProductPageWithCategory;

  if (withProductPageRedirect) {
    const ean = isOldUberProductPageWithCategory ? segments[3] : segments[2];
    const productData = await getProductDetailsData({ ean, language: langSegment });

    if(!productData) {
      return {
        isNotFound: true,
        path,
      };
    } else {
      return {
        path: getProductUrl({
          lang: langSegment,
          ean,
          slug: productData.product.slug,
        }),
      };
    }
  }

  return {
    path,
  };
};

