import { Category } from "src/api/types";
import { ActiveFilter } from "src/modules/filters/utils/getActiveFiltersObjects";
import useLocalize from "src/services/localize/useLocalize";
import findCategoryObject from "src/utils/categories/findCategoryObject";
import upperCaseFirstChar from "src/utils/js-helpers/upperCaseFirstChar";
import personalizeString from "src/utils/personalize";

interface Params {
  activeFilters: ActiveFilter[];
  selectedCategory: Category;
  categories: Category[];
}

const maxFiltersCount = 3;

const useCategoryTitleDescription = (params: Params) => {
  const { activeFilters, selectedCategory, categories } = params;

  const parentCategory = findCategoryObject(categories, selectedCategory.parent_id);

  const localize = useLocalize();
  const categoryName = upperCaseFirstChar(selectedCategory.title);

  const filterNames = activeFilters
    .slice(0, maxFiltersCount)
    .filter(i => i?.option?.name !== undefined)
    .map(i => i?.option?.name)
    .join(', ');

  let h1 = categoryName;
  let metaTitle = personalizeString(localize('catalog.meta.category.title'), [categoryName]);
  let metaDescription = personalizeString(localize('catalog.meta.category.description'), [categoryName]);

  if (parentCategory) {
    const parentCategoryName = parentCategory.title;

    metaTitle = personalizeString(localize('catalog.meta.category_with_parent.title'), [categoryName, parentCategoryName]);
    metaDescription = personalizeString(localize('catalog.meta.category_with_parent.description'), [categoryName, parentCategoryName]);
  }

  if (filterNames.length > 0 ) {
    h1 = upperCaseFirstChar(`${categoryName} ${filterNames}`);
    metaTitle = personalizeString(localize('catalog.meta.filters.title'), [h1]);
    metaDescription = personalizeString(localize('catalog.meta.filters.description'), [h1]);
  }

  return {
    h1,
    metaTitle,
    metaDescription,
  };
};

export default useCategoryTitleDescription;
