import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { desktop, phone, tablet } from '../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .Footer {
      border-top: 4px solid ${theme.base.color.gray100};
    }
    .Footer__topLine {
      padding: 52px 0;
      display: flex;
      justify-content: space-between;
    }
    .Footer__column {
      width: 33.33%;
    }
    .Footer__column_wide {
      width: 66.66%;
    }
    .Footer__bottomLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-top: 1px solid ${theme.base.color.gray300};
    }
    .Footer__logo {
      margin-bottom: 24px;
    }
    .Footer__lang {
      margin-bottom: 16px;
    }
    .Footer__zsuLink {
      margin-bottom: 24px;
    }

    @media ${desktop} {
      .Footer__flexWrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .Footer__logo {
        margin-bottom: 32px;
      }
    }

    @media ${tablet} {
      .Footer__topLine {
        padding: 60px 32px 16px;
      }
      .Footer__bottomLine {
        padding: 20px 32px;
      }
      .Footer__column {
        width: 50%;
      }

    }

    @media ${phone} {
      .Footer {
        padding: 28px 15px 0;
      }
      .Footer__topLine {
        display: block;
        padding: 0;
      }
      .Footer__bottomLine {
        padding: 11px 0;
      }
      .Footer__column {
        margin-bottom: 24px;
        width: 100%;
      }
      .Footer__logo {
        max-width: 140px;
      }
    }
  `;
};

export default getStyles;
