import cn from 'classnames';
import { Fragment, ReactNode } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import getStyles from './PageWrap.styles';

interface Props {
  opaqueBackground?: boolean;
  children: ReactNode;
}

export const PageWrap = (props: Props) => {
  const { opaqueBackground, children } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <div
        className={cn(
          'PageWrap', {
          'PageWrap_opaqueBackground': opaqueBackground,
      })}>
        {children}
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};


export default PageWrap;
