import { desktop } from 'src/styles/media';
import css from 'styled-jsx/css';
import { Theme } from '../theme/types';

export const getStyles = (theme: Theme) => {
  return css`
  .SliderField {
    position: relative;
    width: 100%;
    height: 20px;
    padding-top: 9px;
    cursor: pointer;
    touch-action: none;
  }

  .SliderField__track {
    height: 2px;
    background-color: ${theme.base.color.gray300};
  }

  .SliderField__thumb {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: ${theme.base.color.primary};
    border: 2px solid ${theme.base.color.white};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .SliderField__track_active {
    z-index: 1;
    position: absolute;
    height: 2px;
    top: 9px;
    left: 0;
    right: 0;
    background-color: ${theme.base.color.primary};
  }

  .SliderField:not(.SliderField_movingMode) .SliderField__thumb,
  .SliderField:not(.SliderField_movingMode) .SliderField__track_active {
    transition: all ease-in-out 0.2s;
  }

  @media ${desktop} {
    .SliderField__thumb {
      width: 18px;
      height: 18px;
    }
  }
`;
};
