import useCategories from "src/services/categories/useCategories";
import { BreadcrumbsItem } from "../components/Breadcrumbs/Breadcrumbs";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import getCategoriesTree from "src/utils/categories/getCategoriesTree";

const useBreadcrumbsItems = (
  categoryId: string,
  customLastBreadcrumb: BreadcrumbsItem | null = null,
): BreadcrumbsItem[] => {
  const lang = useLang();
  const localize = useLocalize();
  const homeBreadcrumb: BreadcrumbsItem = { url: `/${lang}/`, title: localize('pages_main'), id: 'main' };
  const categories = useCategories();

  const breadcrumbsItems = getCategoriesTree(categories, categoryId, lang);
  const lastBreadcrumb = customLastBreadcrumb ? [customLastBreadcrumb] : [];

  return [homeBreadcrumb, ...breadcrumbsItems, ...lastBreadcrumb ];
};

export default useBreadcrumbsItems;
