import { tablet } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .Radio {
      display: flex;
      justify-content: flex-start;
      position: relative;
      font-size: 14px;
      line-height: 24px;
    }

    .Radio[disabled],
    .Radio_disabled {
      opacity: 0.5;
    }

    .Radio[disabled] .Radio__label,
    .Radio_disabled .Radio__label {
      cursor: not-allowed;
    }

    .Radio__label {
      max-width: 100%;
      cursor: pointer;
      padding-left: 8px;
    }

    .Radio__label_fullWidth {
      width: 100%;
    }

    .Radio__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 20px;
      height: 20px;
      margin: 0;
      opacity: 0;
      cursor: pointer;
    }

    .Radio__checkmark {
      width: 24px;
      height: 24px;
    }

    .Radio__checkmark_checked,
    .Radio__checkmark {
      position: relative;
      user-select: none;
      pointer-events: none;
    }
    .Radio__checkmark::before,
    .Radio__checkmark_checked::after {
      content: '';
      position: absolute;
      border-radius: 50%;
    }
    .Radio__checkmark::before {
      border: 1px solid;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      background-color: ${theme.base.color.white};
      border-color: ${theme.base.color.gray400};
    }
    .Radio:hover .Radio__checkmark:not(.Radio__checkmark_checked):before {
      border-color: ${theme.base.color.gray500};
    }
    .Radio__checkmark_checked::after {
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      background-color: ${theme.base.color.white};
    }
    .Radio__checkmark_checked::before {
      border-color: ${theme.base.color.primary};
      background-color: ${theme.base.color.primary};
    }

    @media ${tablet} {
      .Radio__label_minPadding {
        padding-left: 2px;
      }
    }

  `;
};

export default getStyles;
