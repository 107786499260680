import { phone, tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
  .CatalogFiltersMobileCategories__categoryItem {
    display: flex;
    align-items: center;
  }
  .CatalogFiltersMobileCategories__activeName {
    ${typography.text}
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.base.color.gray600};
  }
  .CatalogFiltersMobileCategories__title {
    flex-shrink: 0;
  }
  .CatalogFiltersMobileCategories__activeFilters {
    margin-left: 4px;
  }
  .CatalogFiltersMobileCategories__headerButton {
    flex-shrink: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: ${theme.base.color.gray600};
  }
  .CatalogFiltersMobileCategories__header {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${theme.base.border.v1} ${theme.base.color.gray200};
  }
  .CatalogFiltersMobileCategories__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media ${tablet} {
    .CatalogFiltersMobileCategories__header {
      padding: 16px 24px;
    }
  }
  @media ${phone} {

    .CatalogFiltersMobileCategories__header {
      padding: 10px;
    }
  }
  `;
};
