import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { desktop, tablet, tablet_desktop } from '../../../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .CatalogProductTile {
      background-color: ${theme.base.color.white};
      padding: 12px;
      position: relative;
    }
    .CatalogProductTile__image_opacity {
      opacity: 0.5
    }
    .CatalogProductTile__price {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: ${theme.base.color.dark};
      margin-top: 30px;
    }
    .CatalogProductTile__price_unavailable {
      color: ${theme.base.color.gray500};
    }
    .CatalogProductTile__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: ${theme.base.color.dark};
      margin-top: 4px;
      height: 48px;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: -o-ellipsis-lastline;
    }
    .CatalogProductTile__title:hover {
      color: ${theme.base.color.primary};
    }
    .CatalogProductTile__weight {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: ${theme.base.color.gray500};
      margin-top: 4px;
      height: 16px;
    }
    @media ${tablet_desktop} {
      .CatalogProductTile {
        padding: 16px;
      }
      .CatalogProductTile__title {
        margin-top: 8px;
      }
    }
    @media ${tablet} {
      .CatalogProductTile__price {
        margin-top: 24px;
      }
    }
    @media ${desktop} {
      .CatalogProductTile__price {
        margin-top: 16px;
      }
    }
  `;
};

export default getStyles;
