import { CatalogProductBase, Stats } from "src/api/types";
import { BASE_URL, CURRENCY } from "src/data/constants";
import { formatPrice } from "src/utils/formatPrice";
import { getProductUrl } from "./getProductUrl";

export interface AggregateOffersParams {
  stats: Stats;
  count: number;
  products: CatalogProductBase[];
  lang: string;
  categoryImage: string | undefined;
  categoryName: string;
  isClosedFromIndexing: boolean;
}

export const getAggregateOffersMicroData = ({
  stats,
  count,
  products,
  lang,
  categoryImage,
  categoryName,
  isClosedFromIndexing,
}: AggregateOffersParams) => {
  if (isClosedFromIndexing) {
    return null;
  }

  const offers = products.map(p => ({
    "@type": "Offer",
    "url": `${BASE_URL}${getProductUrl({ lang, ean: p.ean, slug: p.slug })}`,
  }));

  return {
    "@context": "https://schema.org",
    "@type": "Product",
    ...(categoryImage && { "image": categoryImage }),
    "name": categoryName,
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": CURRENCY,
      "highPrice": formatPrice(stats.price.min),
      "lowPrice": formatPrice(stats.price.max),
      "offerCount": count,
      offers,
    },
  };
};
