import { baseVariablesCSS } from 'src/ui-kit/theme/skins/base';
import css from 'styled-jsx/css';

const getStyles = () => {
  return css.global`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    .grecaptcha-badge {
      opacity: 0;
      pointer-events: none;
      z-index: 100;
    }

    body {
      font: 16px / 1 ${baseVariablesCSS['--base-font-family-1']};
      color: ${baseVariablesCSS['--base-color-dark']};
    }

    a {
      text-decoration: none;
    }
  `;
};

export default getStyles;
