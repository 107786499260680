import css from 'styled-jsx/css';

interface Params {
  lines: number;
  withTruncating: boolean;
}

export const getStyles = ({ lines, withTruncating }: Params) => {
  const lineHeight = 1.5;

  return css.resolve`
    .Truncate {
      text-align: left;
    }
    .Truncate__content {
      margin: 0 0 10px;
      line-height: ${lineHeight};
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${withTruncating ? lines : 'none'};
      max-height: ${withTruncating ? `${lines * lineHeight}em` : 'none'};
    }
  `;
};
