import { ReactNode } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../ui-kit/IconFont';
import getStyles from './GeneralDrawerModalLayout.styles';

interface GeneralDrawerModalLayoutProps {
  children: ReactNode;
  title: ReactNode;
  onClose(): void;
}

const GeneralDrawerModalLayout = (props: GeneralDrawerModalLayoutProps) => {
  const {
    children,
    title,
    onClose,
  } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div className='GeneralDrawerModalLayout' data-marker="drawer-modal">
      <div className="GeneralDrawerModalLayout__header">
        <div className="GeneralDrawerModalLayout__title">{ title }</div>
        <button className="GeneralDrawerModalLayout__buttonClose" onClick={onClose}>
          <IconFont name='close' size='14px' color='dark' />
        </button>
      </div>
      <div className="GeneralDrawerModalLayout__content">
        {children}
      </div>
      <style jsx>{ styles }</style>
    </div>
  );
};

export default GeneralDrawerModalLayout;
