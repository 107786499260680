import { Delivery } from "src/modules/delivery/types";
import { RetailChain, QueryString, TimeWindow } from '../../../api/types';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { RetailInfo } from "../types";
import getRetailInfoLinkUrl from './getRetailInfoLinkUrl';
import getTimeWindowText from './getTimeWindowText';

interface GetRetailInfoParams {
  isAvailable: boolean;
  retailChain: RetailChain;
  cartItemsCount?: number;
  timeWindow?: TimeWindow | null;
  deliveryType: Delivery['type'] | null;
  deliveryAsQueryStringForLink: QueryString;
  specificRouteForLink?: string;
  language: string;
  price?: number | null;
  localize: LocalizeFunc;
}

const getRetailInfo = (params: GetRetailInfoParams): RetailInfo => {
  const {
    isAvailable,
    retailChain,
    cartItemsCount,
    timeWindow = null,
    deliveryType,
    deliveryAsQueryStringForLink,
    specificRouteForLink,
    language,
    price,
    localize,
  } = params;

  const retailChainLinkUrl = getRetailInfoLinkUrl({
    language,
    retailChain,
    withDeliveryData: isAvailable,
    specificRoute: specificRouteForLink,
    deliveryAsQueryStringForLink});

  const retailInfo: RetailInfo = {
    id: retailChain.id,
    chainName: retailChain.name,
    linkUrl: retailChainLinkUrl,
    logoUrl: retailChain.logo.logo,
    cartCount: cartItemsCount,
    price,
  };

  if (isAvailable) {
    const timeWindowHasError = !!deliveryType && !timeWindow;

    retailInfo.timeWindowText = getTimeWindowText(timeWindow, deliveryType, localize);
    retailInfo.timeWindowHasError = timeWindowHasError;

    if (timeWindow) {
      const tooltipData = new Date(timeWindow.end_ordering_time)
        .toLocaleString(language, {
          hour: 'numeric',
          minute: 'numeric',
          month: 'long',
          day: 'numeric',
        });

      retailInfo.timeWindowTooltip = `${localize('general.order.info')} ${tooltipData}`;
    }
  }

  return retailInfo;
};

export default getRetailInfo;
