import handleCase from './handlers/handleCase';
import handleDoubleSlash from './handlers/handleDoubleSlash';
import handleHelpPages from './handlers/handleHelpPages';
import handleNoLanguage from './handlers/handleNoLanguage';
import handleNotifications from './handlers/handleNotifications';
import { handleOldUberCategories } from './handlers/handleOldUberCategories';
import { handleOldUberProduct } from './handlers/handleOldUberProduct';
import { handleOldUberSearch } from './handlers/handleOldUberSearch';
import handlePreferredLanguage from './handlers/handlePreferredLanguage';

export type RedirectStatusCode = 301 | 302;

export interface HandlerResult {
  path: string;
  redirectType?: RedirectStatusCode;
  isNotFound?: boolean;
  stopModifying?: boolean;
}

export interface RedirectResult extends HandlerResult {
  isModified?: boolean;
  redirectType: RedirectStatusCode;
}

export interface RedirectContext {
  preferredLanguage?: string;
}

export interface HandlerContext extends RedirectContext{
  path: string;
}

export interface PathHandler {
  (ctx: HandlerContext): Promise<HandlerResult> | HandlerResult;
}

interface GetRedirectPathFunc {
  (initialPath: string, handlers: PathHandler[], ctx: RedirectContext): Promise<RedirectResult>;
}

export const handlers: PathHandler[] = [
  handleNotifications,
  handleCase,
  handleNoLanguage,
  handlePreferredLanguage,
  handleHelpPages,
  handleDoubleSlash,
  handleOldUberCategories,
  handleOldUberSearch,
  handleOldUberProduct,
];

const getRedirectPath: GetRedirectPathFunc = async (initialPath, handlers, ctx) => {
  let result: RedirectResult = { path: initialPath } as RedirectResult;

  for (let handler of handlers) {
    const {
      path,
      isNotFound,
      redirectType,
      stopModifying,
    } = await handler({ ...ctx, path: result.path });

    result = {
      path,
      isNotFound: !!isNotFound,
      redirectType: redirectType || 301,
      isModified: initialPath !== path,
    };

    if (stopModifying || isNotFound) {
      return result;
    }
  }

  return result;
};

export default getRedirectPath;
