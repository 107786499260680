import { Category } from "../../api/types";

export function findCategoryTitle(array: Category[], categoryId: Category['id']): string | undefined {
  for (let i = 0; i < array.length; i++) {
    const child = array[i];
    if (child.id === categoryId) {
      return child.title;
    }

    if (child.children.length > 0) {
      const innerIndent = findCategoryTitle(child.children, categoryId);

      if (innerIndent) {
        return innerIndent;
      }
    }
  }
}
