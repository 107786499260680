import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";

interface CatalogFiltersMobileFooterProps {
  onClick: () => void;
  isChanged: boolean;
}

const CatalogFiltersMobileFooter = ({ onClick, isChanged }: CatalogFiltersMobileFooterProps) => {
  const theme = useTheme();
  const localize = useLocalize();

  return (
    <StylishBox
      sbs={{
        mt: 'auto',
        borderTop: `${theme.base.border.v1} ${theme.base.color.gray200}`,
        display: 'flex',
        alignItems: 'center',
        height: '96px',
        p: {
          general: '24px 32px',
          phone: '16px',
        },
      }}
    >
      <Button
        onClick={onClick}
        variant={isChanged ? 'primarySmall' : 'secondarySmall'}
        dataMarker={isChanged ? 'Submit Button' : 'Close Button'}
      >
        {localize(isChanged ? 'filters.button.submit' : 'general.close')}
      </Button>
    </StylishBox>
  );
};

export default CatalogFiltersMobileFooter;
