import { Category } from "src/api/types";

const getCategoryLevel = (categories: Category[], categoryId: string, initialLevel = 0) => {
  const currentLevel = initialLevel + 1;

  for (const category of categories) {
    if (category.id === categoryId) {
      return currentLevel;
    }

    if (category.children.length > 0) {
      const level = getCategoryLevel(category.children, categoryId, currentLevel);
      if (level) {
        return level;
      }
    }
  }

  return undefined;
};

export default getCategoryLevel;
