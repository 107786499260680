import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .Dropdown {
      position: relative;
      display: flex;
      align-items: center;
      height: 21px;
      ${typography.text};
    }
    .Dropdown__selected {
      display: flex;
      align-items: center;
      padding: 0 2px 0 0;
      border: none;
      background: none;
      cursor: pointer;
    }
    .Dropdown__icon {
      margin-left: 7px;
    }
    .Dropdown__menu {
      position: absolute;
      top: 100%;

      display: none;
      padding-top: 12px;
    }
    .Dropdown__menu_right {
      right: 0;
    }
    .Dropdown__menu_left {
      left: 0;
    }
    .Dropdown__menu_center {
      left: 50%;
      transform: translateX(-50%);
    }
    .Dropdown__menuInner {
      padding: 12px 24px;
      box-shadow: 0px 2px 8px rgba(44, 49, 44, 0.1);
      background-color: ${theme.base.color.white};
    }
    .Dropdown__menu:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 10px 6px;
      border-color: transparent transparent ${theme.base.color.white} transparent;
      position: absolute;
      top: 2px;
    }
    .Dropdown__menu::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 12px 7px;
      border-color: transparent transparent ${theme.base.color.gray100} transparent;
      position: absolute;
      top: -0;
    }
    .Dropdown__menu.Dropdown__menu_left:after {
      left: 1px;
    }
    .Dropdown__menu.Dropdown__menu_right:after {
      right: 1px;
    }
    .Dropdown__menu.Dropdown__menu_center:after {
      left: 50%;
      transform: translateX(-50%);
    }
    .Dropdown__menu.Dropdown__menu_left:before {
      left: 0;
    }
    .Dropdown__menu.Dropdown__menu_right:before {
      right: 0;
    }
    .Dropdown__menu.Dropdown__menu_center:before {
      left: 50%;
      transform: translateX(-50%);
    }
    .Dropdown:hover .Dropdown__menu {
      display: block;
    }
    .Dropdown:hover .Dropdown__icon {
      transform: rotate(-180deg);
      color: ${theme.base.color.primary};
    }
  `;
};

export default getStyles;
