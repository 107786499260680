import Spinner from "src/modules/ubercatalog/components/Spinner/Spinner";
import StylishBox from "../StylishBox/StylishBox";
import { useTheme } from "../theme";

interface PagePreloaderProps {
  loading: boolean;
}

const PagePreloader = ({ loading }: PagePreloaderProps) => {
  const theme = useTheme();

  if (!loading) {
    return null;
  }

  return (
    <StylishBox
      sbs={{
        position: 'fixed',
        zIndex: '999',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StylishBox
        sbs={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: theme.base.color.white,
          opacity: '0.48',
        }}
      />
      <Spinner color={theme.base.color.primary} size="44px" />
    </StylishBox>
  );
};

export default PagePreloader;
