import { DEFAULT_LANG, LANGUAGES } from 'src/data/constants';
import { PathHandler } from '../getRedirectPath';
import getSegmentInfo from '../helpers/getSegmentInfo';

export const SITE_ROUTS = [
  'products',
  'categories',
  'contacts',
  'account',
  'uikit',
  'app',
  'help',
  'terms',
  'policy',
  'cashless-payment',
  'pickerman_policy',
];

export const NO_LANG_ROUTES = [
  '/auth',
  '/robots.txt',
  '/manifest.json',
  '/push-worker.js',
  '/apple-app-site-association',
  '/.well-known/assetlinks.json',
];

const handleNoLanguage: PathHandler = ({ path }) => {
  const { segments, params, langSegment } = getSegmentInfo(path);
  const paramsPart = params ? `?${params}` : '';

  /**
   * If there are no segments, it means the path is "/",
   * and the default language should be added.
   */
  if (!segments.length) {
    const newPath = `/${DEFAULT_LANG}/` + paramsPart;

    return { path: newPath };
  }

  /**
   * If path corresponds to one of the special language-less routes,
   * it means that the redirects should be interrupted to avoid further modifications.
   */
  const isNoLangRoute = NO_LANG_ROUTES.some((r) => path.includes(r));

  if (isNoLangRoute) {
    return { path, stopModifying: true };
  }

  /**
   * If langSegment does not match any of the available languages in LANGUAGES,
   * it means that the language is invalid.
   */
  const languageIsValid = LANGUAGES.includes(langSegment);

  if (!languageIsValid) {
    /**
     * If langSegment is a known siteRoute, it means that the path without a language,
     * and the default language should be inserted.
     */
    const needInsertLangSegment = SITE_ROUTS.includes(langSegment);

    if (needInsertLangSegment) {
      const newPath = ['', DEFAULT_LANG, ...segments, ''].join('/') + paramsPart;

      return { path: newPath };
    }

    /**
     * If the second segment is a known siteRoute, it means that the language is incorrect,
     * and it should be replaced with the default language.
     */
    const needChangeUnsupportedLang = SITE_ROUTS.includes(segments[1]);

    if (needChangeUnsupportedLang) {
      const [, ...restSegments] = segments;
      const newPath = ['', DEFAULT_LANG, ...restSegments, ''].join('/') + paramsPart;

      return { path: newPath };
    }
  }

  return { path };
};

export default handleNoLanguage;
