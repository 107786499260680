import { LocalizeFunc } from "../../services/localize/useLocalizeService";

export function getImgTitle(productTitle: string, localize: LocalizeFunc) {
  return [
    productTitle,
    `- ${localize('general.photo')}`,
  ].join(' ');
}

export function getImgAlt(productTitle: string, localize: LocalizeFunc) {
  return [
    productTitle,
    localize('seo.img_title_1'),
    `- ${localize('general.photo')}`,
  ].join(' ');
}
