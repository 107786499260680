import { phone, tablet } from "src/styles/media";
import css from "styled-jsx/css";

export const getStyles = () => {
  return css`
    .ProductPageTabs {
      padding: 56px 0;
    }

    @media ${tablet} {
      .ProductPageTabs {
        padding: 40px 0;
      }
      .ProductPageTabs__tabsPanel {
        margin: 0 -30px;
      }
      .ProductPageTabs__tab {
        padding: 0 30px;
      }
    }

    @media ${phone} {
      .ProductPageTabs {
        padding: 32px 0;
      }
      .ProductPageTabs__tabsPanel {
        margin: 0 -15px;
      }
      .ProductPageTabs__tab {
        padding: 0 15px;
      }
    }
  `;
};
