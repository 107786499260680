import { Fragment } from 'react';
import IconFont from '../../../../ui-kit/IconFont';
import getStyles from './ThumbsNavigationControls.styles';

interface Props {
  controls: {
    onPrevSlide?(): void;
    onNextSlide?(): void;
    sliderState?: {
      isEndPositionLeft: boolean;
      isEndPositionRight: boolean;
    };
  };
}

const ThumbsNavigationControls = (props: Props) => {
  const { controls: { onPrevSlide, onNextSlide, sliderState } } = props;
  // todo: please remove ts-ignore and fix errors when changing this file
  // @ts-ignore
  const { isEndPositionLeft, isEndPositionRight } = sliderState;
  const styles = getStyles();

  return (
    <Fragment>
      {
        !isEndPositionLeft &&
        <button
          type='button'
          className='ThumbsNavigationControl ThumbsNavigationControl_left'
          data-marker='Previous slide'
          onClick={onPrevSlide}
        >
          <IconFont name='arrow-small-left' />
        </button>
      }

      {
        !isEndPositionRight &&
        <button
          type='button'
          className='ThumbsNavigationControl ThumbsNavigationControl_right'
          data-marker='Next slide'
          onClick={onNextSlide}
        >
          <IconFont name='arrow-small-right' />
        </button>
      }
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default ThumbsNavigationControls;
