import { useTheme } from 'src/ui-kit/theme';
import getStyles from './SeoText.styles';

export interface SeoTextProps {
  content: string;
}

const SeoText = (props: SeoTextProps) => {
  const { content } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div className="SeoText" data-marker="SeoText">
      <div className="SeoText__wrapper">
        <div
          className="SeoText__content"
          dangerouslySetInnerHTML={ { "__html": content } }
        />
      </div>
      <style jsx>{ styles }</style>
    </div>
  );
};

export default SeoText;
