import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .DesktopSwitchLocale__menuItem:not(:first-child) {
      margin-top: 12px;
    }
    .DesktopSwitchLocale__menuLink {
      ${typography.text}
      text-decoration: none;
    }
    .DesktopSwitchLocale__menuLinkActive,
    .DesktopSwitchLocale__menuLink:hover,
    .DesktopSwitchLocale__menuLink:active {
      color: ${theme.base.color.primary};
    }
  `;
};

export default getStyles;
