import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from "react";
import { clampValueToLimits } from "src/modules/filters/utils/clampValueToLimits";
import { RangeNumbers } from "src/ui-kit/SliderField/SliderField";
import { FilterPriceProps } from './FilterPrice';

const filterInputValue = (value: string) => {
  const filteredValue = value.replaceAll(/\D/g, '');

  return filteredValue? Number(filteredValue) : 0;
};

const convertValueToCoins = (value: string) => filterInputValue(value) * 100;

export interface UseFilterPriceParams {
  range: {
    min: number;
    max: number;
  };
  values: {
    min: number;
    max: number;
  };
}

const useFilterPrice = (params: UseFilterPriceParams) => {
  const { range, values } = params;

  const minValue = values.min;
  const maxValue = values.max;
  const minRange = range.min;
  const maxRange = range.max;

  const [rangeValue, setRangeValue] = useState<RangeNumbers>([minValue, maxValue]);
  const [inputMinValue, setInputMinValue] = useState<number>(minValue);
  const [inputMaxValue, setInputMaxValue] = useState<number>(maxValue);
  const step = Math.floor((maxRange - minRange) / 10000) * 100 || 100;
  const rangeValueRef = useRef<RangeNumbers>();
  rangeValueRef.current = rangeValue;

  const setRangeValueToLocalStates = useCallback((rangeValue: RangeNumbers) => {
    setRangeValue(rangeValue);
    setInputMinValue(rangeValue[0]);
    setInputMaxValue(rangeValue[1]);
  }, []);

  useEffect(() => {
    const curFilterValue = rangeValueRef.current;
    // При изменении значения одной из пропс: min, max,
    // если входящее значение фильтра не совпадает с текущим,
    // пересчитать значения локальных состояний фильтра.
    // Это не обходимо для обработки удаления фильтра с другой части UI,
    // а так же, при изменении значений min и max в ответе сервера,
    // при взаимодействии пользователем с другими типами фильтров на UI.

    if(!isEqual([minValue, maxValue], curFilterValue)) {
      const newRangeValue: RangeNumbers = Number.isFinite(minValue) && Number.isFinite(maxValue)
        ? [minValue, maxValue]
        : [minRange, maxRange];

      setRangeValueToLocalStates(newRangeValue);
    }
  }, [minValue, maxValue, minRange, maxRange, setRangeValueToLocalStates]);

  const handleChangeThumbsPositions = (newRangeValue: RangeNumbers) => {
    setRangeValueToLocalStates(newRangeValue);
  };

  const getNewRangeValueFromInputs = (): RangeNumbers => {
    const newMinValue = clampValueToLimits(Math.min(inputMinValue, inputMaxValue), minRange, maxRange);
    const newMaxValue = clampValueToLimits(Math.max(inputMinValue, inputMaxValue), minRange, maxRange);

    const newRangeValue: RangeNumbers = [newMinValue, newMaxValue];

    return newRangeValue;
  };

  const handleChangeInput = (name: 'min' | 'max', value: string) => {
    const convertedValue = convertValueToCoins(value);

    if(name === 'min') {
      setInputMinValue(convertedValue);
    } else {
      setInputMaxValue(convertedValue);
    }
  };

  const filterPriceProps: FilterPriceProps = {
    sliderFieldProps: {
      ...range,
      step,
      value: rangeValueRef.current,
      onChange: handleChangeThumbsPositions,
    },
    inputsProps: {
      min: inputMinValue,
      max: inputMaxValue,
      onChange: handleChangeInput,
    },
  };

  return {
    filterPriceProps,
    getNewRangeValueFromInputs,
  };
};

export default useFilterPrice;
