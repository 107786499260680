import { getCategoriesUrl } from "src/modules/ubercatalog";
import { PathHandler } from "../getRedirectPath";
import getSegmentInfo from "../helpers/getSegmentInfo";

export const handleOldUberCategories: PathHandler = (ctx) => {
  const {
    path,
  } = ctx;

  const { segments, langSegment } = getSegmentInfo(path);

  const isOldUberCategoryPage = segments.length === 3
    && segments[1] === 'products'
    && !segments[2].includes('search_query') //old search page (handles in handleOldUberSearch)
    && !segments[2].includes('--'); //new product page /products/{slug}--{ean}

  if (isOldUberCategoryPage) {
    const categoryId = segments[2];

    const newPath = getCategoriesUrl({
      lang: langSegment,
      categoryId: `${categoryId}-zakaz`,
    });

    return {
      path: newPath,
    };
  }

  return {
    path,
  };
};

