import { ExperimentsMap } from "./types";

/**
 * @todo: move this config to DB after implementation env variables to DB
 */
export const experimentsConfig: Record<string, ExperimentsMap> = {
  ua: {
    example_id: {
      endDate: '2033-11-30',
      distribution: [30, 40, 30],
    },
    product_retails: {
      endDate: '2033-11-30',
      distribution: [100, 0],
    },
  },
};
