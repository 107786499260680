import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
  .ProductBtnZoomImage {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    padding: 8px;
    z-index: 1;
  }
  `;
};

export default getStyles;