import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

const getStyles = (theme: Theme) => {
  return css`

    .ProductBox__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .ProductBox__count {
      ${typography.text}
      color: ${theme.base.color.gray600};
    }

    .ProductBox__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    .ProductBox__listItem {
      flex-shrink: 0;
      border-style: solid;
      border-width: 0 1px 1px 0;
      border-color: ${theme.base.color.gray200};
      width: 25%;
      cursor: pointer;
      text-decoration: none;
    }

    .ProductBox__loadMore {
      width: 188px;
      margin: 40px auto 20px;
    }

    .ProductBox__pagination_withMargin {
      margin-top: 40px;
    }

    @media ${phone} {
      .ProductBox__listItem {
        width: 50%;
      }
    }
  `;
};

export default getStyles;
