import { phone } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .ContentSortModal {
      background: ${theme.base.color.white};
    }
    .ContentSortModal__title {
      padding: 12px 32px;
      border-bottom: ${theme.base.border.v1} ${theme.base.color.gray200};
    }
    .ContentSortModal__optionList {
      padding: 10px 32px;
    }
    .ContentSortModal__option {
      padding: 4px 0;
    }
    .ContentSortModal__option:not(:last-child) {
      margin-bottom: 10px;
    }

    @media ${phone} {
      .ContentSortModal__title {
        padding: 12px 16px;
      }

      .ContentSortModal__optionList {
        padding: 8px 16px;
      }
    }
  `;
};
