import cn from 'classnames';
import { Fragment, ReactNode } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import getDataStatus from 'src/utils/getDataStatus';
import getStyles from './Radio.styles';

interface RadioProps {
  checked: boolean;
  name: string;
  value: string;
  dataMarkerValue?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick: () => void;
  onInputHover?: () => void;
  onInputBlur?: () => void;
  children: ReactNode;
  minPadding?: boolean;
}

const Radio = (props: RadioProps) => {
  const {
    name,
    checked,
    value,
    disabled,
    dataMarkerValue,
    children,
    onClick,
    onInputHover,
    onInputBlur,
    fullWidth,
    minPadding,
  } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <div
        className={cn('Radio', {
          Radio_disabled: disabled,
          Radio_checked: checked,
        })}
      >
        <input
          className='Radio__input'
          onChange={onClick}
          onMouseOver={onInputHover}
          onMouseOut={onInputBlur}

          type='radio'
          name={name}
          checked={checked}
          value={value}
          id={`${name}-${value}`}
          disabled={disabled}

          aria-disabled={disabled}

          data-testid='Radio-button'
          data-marker='Radio button'
          data-marker-value={dataMarkerValue}
          data-status={getDataStatus(checked)}
        />

        <span className={cn('Radio__checkmark', { 'Radio__checkmark_checked': checked })}/>

        <label
          htmlFor={`${name}-${value}`}
          className={cn(
            'Radio__label', {
            'Radio__label_fullWidth': fullWidth,
            'Radio__label_minPadding': minPadding,
          })}
        >
          {children}
        </label>

        <style jsx>{styles}</style>
      </div>
    </Fragment>
  );
};

Radio.defaultProps = {
  onInputHover: () => {},
  onInputBlur: () => {},
};

export default Radio;
