import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

const getStyles = () => {
  return css`
    .ProductAbout__item {
      flex: 0 1 329px;
      ${typography.text}
    }

    .ProductAbout__item_wide {
      flex-basis: 100%;
      margin-bottom: 24px;
    }

    .ProductAbout__itemTitle {
      ${typography.h4}
      margin-bottom: 12px;
    }

    .ProductAbout__items {
      display: flex;
      justify-content: space-between;
    }

    .ProductAbout__listItem {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 11px;
    }

    @media ${phone} {
      .ProductAbout__items {
        display: block;
      }

      .ProductAbout__item {
        margin-bottom: 24px;
      }
    }
 `;
};

export default getStyles;
