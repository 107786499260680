import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone, tablet } from '../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .Messengers {
      position: fixed;
      bottom: 32px;
      right: 32px;
      z-index: ${theme.base.zIndex.zendesk};
      user-select: none;
    }

    .Messengers__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .Messengers__button,
    .Messengers__launcher {
      display: block;
      width: 40px;
      cursor: pointer;
    }

    .Messengers__button:hover,
    .Messengers__launcher:hover {
      opacity: .86;
      transition: all 0.2s ease-out;
    }

    .Messengers__button {
      animation: fadeUp ease;
    }

    .Messengers__button:nth-child(1n) {
      animation-duration: 120ms;
    }

    .Messengers__button:nth-child(2n) {
      animation-duration: 140ms;
    }

    .Messengers__button:nth-child(3n) {
      animation-duration: 160ms;
    }

    .Messengers__button:nth-child(4n) {
      animation-duration: 180ms;
    }

    .Messengers__button:nth-child(5n) {
      animation-duration: 200ms;
    }

    .Messengers__icon {
      display: block;
      max-width: 100%;
    }

    @media ${tablet} {
      .Messengers {
        right: 20px;
        bottom: 70px;
      }
    }

    @media ${phone} {
      .Messengers {
        right: 20px;
        bottom: 60px;
      }
    }

    @keyframes fadeUp {
      from {
        transform: translateY(10px);
        opacity: 0.3;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  `;
};

export default getStyles;
