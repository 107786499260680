import { useMemo } from 'react';
import { NutritionFacts, Taxon } from '../../../../api/types';
import { useLocalize } from '../../../../services/localize/useLocalize';
import getStyles from './ProductAbout.styles';

export interface ProductAboutProps {
  ingredients: string[];
  nutritionFacts: NutritionFacts;
  taxons: Taxon[];
}

const taxonsTypes = [
  'tm',
  'pr',
  'importer',
  'volume',
  'weight',
  'volume_capacity',
  'fat',
  'shelf_life',
  'trange',
  'country',
  'amount',
  'genus_species',
  'variety',
  'sausage_kind',
  'cheese_kind',
  'macaroni_method',
  'chocolate_kind',
  'deodorant_type',
  'method',
  'years',
  'flavor',
  'base',
  'den',
  'for',
  'children_from_month',
  'size_diapers',
  'material',
  'wattage',
  'size_battery',
];

type TaxonsMap = {
  [taxonType: string]: Taxon;
};

const getTaxons = (taxons: Taxon[]) => {
  const initTaxonsMap: TaxonsMap = {};

  return taxons.reduce((acc, taxon) => {
    acc[taxon.type] = taxon;
    return acc;
  }, initTaxonsMap);
};

const ProductAbout = (props: ProductAboutProps) => {
  const { ingredients, nutritionFacts, taxons } = props;
  const styles = getStyles();
  const localize = useLocalize();
  const taxonsMap = useMemo(() => getTaxons(taxons), [taxons]);
  const hasPrTaxon = !!taxonsMap['pr'];
  const hasImporterTaxon = !!taxonsMap['importer'];
  const displayedTaxons = taxons.filter(t => taxonsTypes.includes(t.type));
  const hasTaxons = !!displayedTaxons.length;
  const hasIngredients = !!ingredients?.length;
  const hasNutritionFacts = !!Object.keys(nutritionFacts).length;

  if (!hasTaxons && !hasIngredients && !hasNutritionFacts) {
    return null;
  }

  return (
    <div className="ProductAbout">
      {hasIngredients &&
        <div className="ProductAbout__item ProductAbout__item_wide">
          <h3 className="ProductAbout__itemTitle">{localize('products.ingredients')}</h3>
          <p>{ingredients.join(', ')}</p>
        </div>
      }

      <div className="ProductAbout__items">
        {hasNutritionFacts &&
          <div className="ProductAbout__item">
            <h3 className="ProductAbout__itemTitle">{localize('products.nutrition-facts.title')}</h3>
            <ul className="ProductAbout__list">
              { Object.entries(nutritionFacts).map(([key, value]) => (
                  <li
                    key={key}
                    className="ProductAbout__listItem"
                  >
                    <span className="ProductAbout__entryTitle">
                      {localize(`product_facts_${key}`)}
                    </span>
                    <span className="ProductAbout__entryValue">
                      {value}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        }

        {hasTaxons && (
          <div className='ProductAbout__item'>
            <h3 className='ProductAbout__itemTitle'>
              {localize('products.general-info')}
            </h3>

            <ul className='ProductAbout__list'>
              {taxonsTypes.map(type => {
                const taxon = taxonsMap[type];

                if (!taxon) {
                  return null;
                }

                // if there are both, show only taxon "pr"
                if (hasPrTaxon && hasImporterTaxon && taxon.type === 'importer') {
                  return null;
                }

                const title = taxon.type === 'country'
                  ? `${taxon.name[0].toUpperCase()}${taxon.name.slice(1)}`
                  : taxon.name;

                return (
                  <li
                    key={taxon.type}
                    className='ProductAbout__listItem'
                    data-marker={`Taxon ${taxon.type}`}
                  >
                    <span className='ProductAbout__entryTitle'>
                      {localize(`product_facts_${taxon.type}`)}
                    </span>
                    <span className='ProductAbout__entryValue'>
                      {title}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProductAbout;
