import { getCategoriesUrl } from 'src/modules/ubercatalog';
import { BreadcrumbsItem } from 'src/modules/ubercatalog/components/Breadcrumbs/Breadcrumbs';
import { Category } from '../../api/types';
import isArrayValid from '../js-helpers/isArrayValid';

export default function getCategoriesTree(
  categories: Category[],
  categoryId: string,
  lang: string,
): BreadcrumbsItem[]  {
  if (categories && categoryId) {
    for (let i = 0; i < categories.length; i += 1) {
      const category = categories[i];

      const url = getCategoriesUrl({
        lang,
        categoryId: category.id,
      });

      const item: BreadcrumbsItem = {
        id: categories[i].id,
        title: categories[i].title,
        url,
      };

      if (categories[i].id === categoryId) {
        return [item];
      }

      const arr = getCategoriesTree(categories[i].children, categoryId, lang);

      if (isArrayValid(arr)) {
        arr.unshift(item);
        return arr;
      }
    }
  }

  return [];
}
