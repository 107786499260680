import { desktop, phone, tablet } from "src/styles/media";
import css from "styled-jsx/css";
import getSliderItemCssWidth from "../../helpers/getSliderItemCssWidth";

export const itemsOnDesktop = 4;
export const itemsOnTablet = 3;
const marginBetweenItems = '16px';

export const styles = css`
  .CategoriesSlider {
    position: relative;
    margin: 24px 0;
  }
  .CategoriesSlider__slide {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 100%;
  }

  @media ${desktop} {
    .CategoriesSlider__item {
      width: ${getSliderItemCssWidth(marginBetweenItems, itemsOnDesktop)};
      margin-bottom: ${marginBetweenItems};
      margin-right: ${marginBetweenItems};
    }

    .CategoriesSlider__item:nth-child(${itemsOnDesktop}n){
      margin-right: 0;
    }
  }

  @media ${tablet} {
    .CategoriesSlider__item {
      width: ${getSliderItemCssWidth(marginBetweenItems, itemsOnTablet)};
      margin-bottom: ${marginBetweenItems};
      margin-right: ${marginBetweenItems};
    }
    .CategoriesSlider__item:nth-child(${itemsOnTablet}n){
      margin-right: 0;
    }

    .CategoriesSlider__slide {
      margin-right: ${marginBetweenItems};
    }
    .CategoriesSlider__slide:last-child {
      margin-right: 0;
    }
  }

  @media ${phone} {
    .CategoriesSlider {
      margin-right: -16px;
    }
    .CategoriesSlider__slide {
      flex-wrap: nowrap;
      width: auto;
    }
    .CategoriesSlider__item {
      width: 91px;
      margin-right: 8px;
    }
  }
`;
