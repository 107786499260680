import GeneralPagesLayout from 'src/layouts/GeneralPagesLayout';
import { useTheme } from 'src/ui-kit/theme';
import { useLocalize } from '../../services/localize/useLocalize';
import getStyles from './Error.styles';

const ServerErrorPage = () => {
  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  return(
    <GeneralPagesLayout>
      <div className="Error">
        <div className="Error__imageBox">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="Error__image" src="/static/images/500.png" alt="500" />
        </div>
        <div className="Error__textBox">
          <div className="Error__number">500</div>
          <div className="Error__title">{localize('oops_server_error')}</div>
          <div className="Error__text">{localize('try_reloading_page_or_check_back')}</div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </GeneralPagesLayout>
  );
};

export default ServerErrorPage;
