import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .BulletsNavigation__bullets {
      display: flex;
      justify-content: center;
    }

    .BulletsNavigation__bullet {
      width: 5px;
      height: 5px;
      margin: 5px;
      padding: 0;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      border: none;
      background-color: ${theme.base.color.gray300};
    }

    .BulletsNavigation__bullet_active {
      background-color: ${theme.base.color.primary};
    }
  `;
};

export default getStyles;
