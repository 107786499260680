import { ParsedUrlQuery } from "querystring";

export const createFilterParam = (type: string, name: string, symbol: '_' | '=') =>
  `${encodeURIComponent(type)}${symbol}${encodeURIComponent(name)}`;

const getSelectedFiltersFromQuery = (query: ParsedUrlQuery): string[] => {
  const encodePair = (key: string, value: string | undefined): string | null => {
    return value !== undefined ? createFilterParam(key, value, '=') : null;
  };

  return Object.entries(query)
    .flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map(val => encodePair(key, val))
        : encodePair(key, value),
    )
    .filter((item): item is string => item !== null);
};


export default getSelectedFiltersFromQuery;
