import Cookies from 'js-cookie';
import { useRouter } from "next/router";
import { SortOption, getActiveSortValue, getSortList, navigateToSort } from "../utils/sortContent";

export interface CatalogSortingProps {
  selectedSortItem: SortOption;
  sortList: SortOption[];
  onSort: (item: SortOption) => void;
}

export const useCatalogSorting = (): CatalogSortingProps => {
  const router = useRouter();
  const {  query } = router;

  const sortList = getSortList(router.pathname);
  const parsedSortCookie = Cookies.get('sort');
  const activeSortKey = getActiveSortValue(query.sort, parsedSortCookie);
  const selectedSortItem = sortList.find(s => s.value === activeSortKey) || sortList[0];

  const onSort = ({ value }: SortOption) => {
    Cookies.set('sort', value);
    navigateToSort(router, value);
  };

  return {
    selectedSortItem,
    sortList,
    onSort,
  };
};

export default useCatalogSorting;
