import Cookies from "js-cookie";

export const getVariantFromCookies = (id: string): string | null => {
  const experimentVariant = Cookies.get(getExperimentCookieKey(id));

  return experimentVariant || null;
};

export const setVariantToCookies = (id: string, variant: number) => {
  Cookies.set(getExperimentCookieKey(id), String(variant), { expires: 365 });
};

export const getExperimentCookieKey = (id: string) => 'experiment_' + id;
