import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
  .ZoomableImageSwitcher {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-in;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    user-select: none;
  }
  .ZoomableImageSwitcher__smallImg {
    width: 100%;
    max-height: 100%;
    pointer-events: none;
  }
  .ZoomableImageSwitcher__largeImg {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .ZoomableImageSwitcher__smallImg_halfOpacity {
    opacity: 0.5;
  }
  `;
};

export default getStyles;
