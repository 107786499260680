import { phone } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .ContactInformation {
      font-family: ${theme.base.fontFamily.accented};
      max-width: 170px;
    }

    .ContactInformation__item {
      font-size: 16px;
      line-height: 1.5;
      color: ${theme.base.color.dark};
      margin-bottom: 16px;
    }

    .ContactInformation__itemAction {
      color: ${theme.base.color.dark};
      text-decoration: none;
    }

    @media ${phone} {
      .ContactInformation {
        max-width: 100%;
      }
    }
  `;
};

export default getStyles;
