import { Fragment } from 'react';
import { Filter } from 'src/api/types';
import useLocalize from 'src/services/localize/useLocalize';
import { Typography } from 'src/ui-kit/Typography';
import { useTheme } from 'src/ui-kit/theme';
import { ActiveFilter } from '../../utils/getActiveFiltersObjects';
import getFilterOptionIsChecked from '../../utils/getFilterOptionIsChecked';
import CheckListItem from '../CheckList/CheckListItem/CheckListItem';
import { HandleFilterFunc } from '../CheckList/useCheckList';
import { getStyles } from './CatalogFiltersMobileCategories.styles';
import CatalogFiltersMobileCategoriesButton from './CatalogFiltersMobileCategoriesButton';

interface CatalogFiltersMobileCategoriesProps {
  filters: Filter[];
  activeFilters: ActiveFilter[];
  openCategory(filter: Filter): void;
  handleFilter: HandleFilterFunc;
  deleteFilters: () => void;
  selectedQueryFilters: string[];
}

const CatalogFiltersMobileCategories = (props: CatalogFiltersMobileCategoriesProps) => {
  const {
    filters,
    activeFilters,
    openCategory,
    handleFilter,
    deleteFilters,
    selectedQueryFilters,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <div className='CatalogFiltersMobileCategories__header'>
        <Typography variant="h5">
          {localize('general.filters')}
        </Typography>
        {activeFilters.length > 0 && (
          <button
            type='button'
            onClick={deleteFilters}
            className='CatalogFiltersMobileCategories__headerButton'
            data-marker="Clear Button"
          >
            <span>{localize('general.clear-all')}</span>
          </button>
        )}
      </div>
      <div className='CatalogFiltersMobileCategories__content'>
        {filters.map(filter => {
          if (filter.type === 'single' && filter.options.length > 0) {
            const option = filter.options[0];
            const isChecked = getFilterOptionIsChecked(selectedQueryFilters, filter.key, option.value);

            return (
              <div
                className='CatalogFiltersMobileCategories__categoryItem'
                key={option.value}
              >
                <CheckListItem
                  filter={filter}
                  option={option}
                  onClick={handleFilter}
                  fullWidth
                  isChecked={isChecked}
                />
              </div>
            );
          }

          return (
            <CatalogFiltersMobileCategoriesButton
              key={filter.key}
              filter={filter}
              activeFilters={activeFilters}
              onClick={openCategory}
            />
          );
        })}
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default CatalogFiltersMobileCategories;

