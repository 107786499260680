import { Fragment } from "react";
import OpenGraphMeta from "src/components/OpenGraphMeta";
import Responsive from "src/components/Responsive";
import ScriptLdJson from "src/components/ScriptLdJson/ScriptLdJson";
import { CatalogFilters, CatalogFiltersEmpty, CatalogFiltersMobile } from "src/modules/filters";
import PagePreloader from "src/ui-kit/PagePreloader/PagePreloader";
import { CategoryProductsResponse, Category as CategoryType } from "../../../../api/types";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CatalogMeta from "../../components/CatalogMeta/CatalogMeta";
import CatalogPageLayout from "../../components/CatalogPageLayout/CatalogPageLayout";
import CategoryPageContentHeader from "../../components/CategoryPageContentHeader/CategoryPageContentHeader";
import PageWrap from "../../components/PageLayout/PageWrap/PageWrap";
import PageWrapAside from "../../components/PageLayout/PageWrapAside/PageWrapAside";
import PageWrapBody from "../../components/PageLayout/PageWrapBody/PageWrapBody";
import ProductBox from "../../components/ProductBox/ProductBox";
import SeoText from "../../components/SeoText/SeoText";
import useCategoryPageProps from "../../hooks/useCategoryPageProps";
import useLoadingServerData from "../../hooks/useLoadingServerData";

export interface CategoryPageProps {
  selectedCategory: CategoryType;
  categoryData: CategoryProductsResponse;
}

const CategoryPage = (props: CategoryPageProps) => {
  const loading = useLoadingServerData();

  const { selectedCategory, categoryData } = props;

  const {
    openGraphMetaProps,
    catalogMetaProps,
    categoryPageContentHeaderProps,
    breadcrumbsProps,
    productBoxProps,
    categoryMicroData,
    filters,
    description,
    isVisibleEmptyFilterState,
  } = useCategoryPageProps({
    selectedCategory,
    categoryData,
  });

  const { activeFilters } = productBoxProps;

  return (
    <CatalogPageLayout
      filterSlot={
        <Responsive displayOn="phoneTablet">
          <CatalogFiltersMobile
            filters={filters}
            activeFilters={activeFilters}
          />
        </Responsive>
      }
    >
      <CatalogMeta {...catalogMetaProps} />
      <Breadcrumbs {...breadcrumbsProps} />
      <CategoryPageContentHeader {...categoryPageContentHeaderProps} />

      <PageWrap>
        <PageWrapAside>
          <Responsive displayOn="desktop">
            <CatalogFilters filters={filters} />
          </Responsive>

        </PageWrapAside>
        <PageWrapBody>
          <PagePreloader loading={loading} />

          {isVisibleEmptyFilterState ? (
            <CatalogFiltersEmpty activeFilters={activeFilters} />
          ) : (
            <Fragment>
              <ProductBox {...productBoxProps} />

              {description &&
                <SeoText content={description} />
              }
            </Fragment>
          )}
        </PageWrapBody>
        {categoryMicroData && (
          <ScriptLdJson body={categoryMicroData} />
        )}
      </PageWrap>
      <OpenGraphMeta {...openGraphMetaProps} />
    </CatalogPageLayout>
  );
};

export default CategoryPage;
