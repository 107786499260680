import { Fragment, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  width?: string;
  dataMarker?: string;
}

const CenteringComponent = (props: Props) => {
  const {
    width = '1150px',
    dataMarker,
    children,
  } = props;
  return (
    <Fragment>
      <div
        className='CenteringComponent'
        data-marker={dataMarker}
      >
        {children}
      </div>
      <style jsx>{`
        .CenteringComponent {
          max-width: ${width};
          margin: 0 auto;
        }
      `}</style>
    </Fragment>
  );
};

export default CenteringComponent;
