import { Bundle } from "src/api/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";

export interface BadgesLocalizationsType {
  bundle: string | null;
}

export interface BadgesInfo {
  bundleValue: Bundle;
}

function getBadgesLocalizations(
  badgesInfo: BadgesInfo,
  localize: LocalizeFunc,
): BadgesLocalizationsType {
  const {
    bundleValue,
  } = badgesInfo;

  const bundle = bundleValue && bundleValue > 1
      ? `${bundleValue} ${localize('general.measure.pcs')}`
      : null;

  return {
    bundle,
  };
}

export default getBadgesLocalizations;
