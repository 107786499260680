import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { desktop } from '../../../../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
  .PageWrap {
    margin: 0 auto;
    width: 100%;
    padding-bottom: 72px;
  }

  .PageWrap_opaqueBackground {
    background-color: ${theme.base.color.white};
  }

  @media ${desktop} {
    .PageWrap {
      display: flex;
    }
  }

  `;
};

export default getStyles;
