import cn from 'classnames';
import { Filter, Option } from 'src/api/types';
import { useTheme } from 'src/ui-kit/theme';
import CheckboxWithAmount from '../../../../../components/CheckboxWithAmount/CheckboxWithAmount';
import { HandleFilterFunc } from '../useCheckList';
import { getStyles } from './CheckListItem.styles';

export interface CheckListItemProps {
  filter: Filter;
  option: Option;
  fullWidth?: boolean;
  onClick: HandleFilterFunc;
  isChecked: boolean;
}

export default function CheckListItem(props: CheckListItemProps) {
  const { filter, option, onClick, fullWidth, isChecked } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div
      data-testid="check-list-item"
      className={cn(
        'CheckListItem', {
        'CheckListItem_fullWidth': fullWidth,
      })}
    >
      <CheckboxWithAmount
        text={option.name}
        amount={option.count >= 0 ? option.count : undefined}
        labelId={option.value}
        checked={isChecked}
        disabled={option.count === 0 && !isChecked}
        onClick={() => onClick(filter.key, option.value)}
      />
      <style jsx>{styles}</style>
    </div>
  );
}
