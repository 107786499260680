import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .Pagination__wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    :global(.Pagination) {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 9px 5px;
      background-color: ${theme.base.color.white};
      border: 1px solid ${theme.base.color.gray200};
      border-radius: 24px;
    }

    :global(.Pagination__break),
    :global(.Pagination__item) {
      display: block;
      width: 30px;
      font-size: 12px;
      line-height: 30px;
      border-radius: 50%;
      text-align: center;
      transition: 0.2s all ease-in-out;
      text-decoration: none;
      color: inherit;
    }

    :global(.Pagination__item_active) {
      background-color: ${theme.base.color.primary};
      color: ${theme.base.color.white};
    }

    :global(.Pagination__item:not(.pagination__item_active):hover) {
      background-color: transparent;
      color: ${theme.base.color.primary};
      cursor: pointer;
    }

    :global(.Pagination__direction) {
      padding: 0 8px;
    }

    :global(.Pagination__directionLink) {
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      font-size: 22px;

      text-decoration: none;
      color: inherit;
    }

    :global(.Pagination__direction_disabled) {
      opacity: 0.5;
    }

    :global(.Pagination__direction:not(.Pagination__direction_disabled))
    :global(.Pagination__direction-link:hover) {
      color: ${theme.base.color.primary};
      cursor: pointer;
    }

    :global(.pagination__break) {
      color: ${theme.base.color.gray400};
    }

  `;
};

export default getStyles;
