import { Bundle } from "src/api/types";
import { LocalizeFunc } from "../../services/localize/useLocalizeService";

function getBundleInfo(bundle: Bundle, localize: LocalizeFunc) {
  if (bundle && bundle > 1) {
    return ` \u2022 ${bundle} ${localize('bundles_in_pack')}`;
  }

  return '';
}

export default getBundleInfo;
