import { useEffect, useState } from "react";
import { COUNTRY } from "src/data/constants";
import { experimentsConfig } from "./experimentsConfig";
import { getExperimentVariant } from "./helpers/getExperimentVariant";
import { DEFAULT_EXPERIMENT_INDEX } from "./helpers/getRandomFromPercentages";
import { sendExperimentIsInitialized } from "./helpers/analytics";

export const useExperiment = (id: string) => {
  const experiments = experimentsConfig[COUNTRY];
  const [variant, setVariant] = useState(DEFAULT_EXPERIMENT_INDEX);

  useEffect(() => {
    const experiment = experiments[id];
    if (experiment) {
      const variant = getExperimentVariant(experiment, id);

      sendExperimentIsInitialized(id, variant);
      setVariant(variant);
    }
  }, [experiments, id]);

  return variant;
};
