import { ParsedUrlQuery } from "querystring";
import { DEFAULT_PRODUCTS_PER_PAGE } from "src/data/constants";
import getParsedFilterPriceValue from "src/modules/filters/utils/getParsedFilterPriceValue";
import { getActiveSortValue } from "src/modules/filters/utils/sortContent";
import { parseCookieString } from "src/utils/js-helpers/parseCookieString";
import makeSearchString, { QueryObject } from "src/utils/system/makeSearchString";

interface Params extends QueryObject {
  price_min?: string;
  price_max?: string;
  category_id?: string;
  sort: string;
  page?: string;
}

const getSearchStringForFetchProductsData = (
  cookie: string,
  query: ParsedUrlQuery,
) => {
  const parsedSortCookie = parseCookieString(cookie);

  const { page, sort, price, categoryId, ...restQuery } = query;
  const activeSort = getActiveSortValue(sort, parsedSortCookie.sort);

  const newPage = Number(page) > 1 ? `${page}` : undefined;

  const params: Params = {
    page: newPage,
    sort: activeSort,
    per_page: `${DEFAULT_PRODUCTS_PER_PAGE}`,
      ...restQuery,
  };


  if (categoryId && !Array.isArray(categoryId)) {
    params.category_id = `${categoryId}`;
  }

  if (price && !Array.isArray(price)) {
    const [min, max] = getParsedFilterPriceValue(price);

    params.price_min = `${min}`;
    params.price_max = `${max}`;
  }

  return makeSearchString(params);
};

export default getSearchStringForFetchProductsData;
