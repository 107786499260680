import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import Logo from 'src/components/Logo/Logo';
import Responsive from 'src/components/Responsive';
import { WITH_UBERCATALOG } from 'src/data/constants';
import useAuth from 'src/services/auth/useAuth';
import Stack from 'src/ui-kit/Stack/Stack';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import HeaderLogin from '../HeaderLogin/HeaderLogin';
import Menu from '../Menu/Menu';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import DesktopSwitchLocale from '../SwitchLocale/DesktopSwitchLocale';
import ZsuButton from '../ZsuButton/ZsuButton';
import { getStyles } from './Header.styles';
import { Page } from 'src/modules/ubercatalog';

const HeaderDesktopSearchBox = dynamic(
  () => import('src/modules/search/components/SearchBox/HeaderDesktopSearchBox'),
  { ssr: false },
);

const HeaderMobileSearchBox = dynamic(
  () => import('src/modules/search/components/SearchBox/HeaderMobileSearchBox'),
  { ssr: false },
);

const CategoriesMenuButton = dynamic(
  () => import('src/modules/categoriesMenu'),
  { ssr: true },
);

interface Props {
  withSearch?: boolean;
  pageForAnalytics?: Page;
}

const separator24 = <StylishBox sbs={{ mr: '24px' }} />;
const separator17 = <StylishBox sbs={{ mr: '17px' }} />;

const Header = (props: Props) => {
  const { withSearch = false, pageForAnalytics } = props;
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <header
      className='Header'
      data-marker='header'
      id='header'
    >
      <Stack
        sbs={{
          maxWidth: '1405px',
          px: {
            general: '32px',
            phone: '16px',
          },
        }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack
          alignItems='center'
        >
          <Stack
            alignItems='center'
            sbs={{
              flexGrow: {
                general: '0',
                phone: '1',
              },
              flexShrink: '0',
            }}
          >
            <SidebarMenu />
            {separator24}
            <Logo />
            {separator17}
          </Stack>
          {
            /**
             * @todo: remove this toggle after release of ubercatalog
             */
            WITH_UBERCATALOG && (
              <Fragment>
                <CategoriesMenuButton pageForAnalytics={pageForAnalytics} />
                {separator24}
              </Fragment>
            )
          }

          {withSearch && (
            <StylishBox
              sbs={{
                width: '100%',
                maxWidth: {
                  desktop: '615px',
                },
              }}
            >
              <HeaderDesktopSearchBox />
            </StylishBox>
          )}
        </Stack>

        <Stack
          alignItems='center'
          justifyContent='flex-end'
          sbs={{ flexGrow: { phone: '0' } }}
        >
          <Responsive displayOn="desktop" >
            <Stack
              alignItems='center'
            >
              {withSearch && separator24}
              <ZsuButton />
              {separator17}
              <DesktopSwitchLocale />
              {separator24}
            </Stack>
          </Responsive>

          {
            !withSearch
              ? (
                <Responsive displayOn='desktop'>
                  <Stack alignItems='center'>
                    <Menu />
                    {separator24}
                  </Stack>
                </Responsive>
              )
              : (
                <Responsive displayOn='phoneTablet'>
                  <HeaderMobileSearchBox />
                </Responsive>
              )
          }

          <HeaderLogin
            isLoggedIn={isLoggedIn}
          />
        </Stack>
      </Stack>
      <style jsx>{styles}</style>
    </header>
  );
};

export default Header;
