import { tablet } from 'src/styles/media';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .ScrollUpButton {
      position: fixed;
      right: 32px;
      bottom: 84px;
      z-index: 9;
      padding: 0;
      border: none;
      cursor: pointer;
      background: ${theme.base.color.gray300};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
      color: ${theme.base.color.gray600};
      transition: 0.2s all ease-in;
    }

    .ScrollUpButton:hover {
      color:${theme.base.color.dark};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    @media ${tablet} {
      .ScrollUpButton {
        right: 20px;
        bottom: 122px;
      }
    }
  `;
};
