import { useEffect, useState } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { UserDeliveryType } from '../../modules/delivery/types';
import getDeliveryFromServerCart from './helpers/getDeliveryFromServerCart';

export const USER_DELIVERY_KEY = 'userDelivery';

export interface UserDeliveryService {
  userDelivery: UserDeliveryType;
  userDeliveryIsReady: boolean;
  updateUserDelivery(delivery: UserDeliveryType): void;
  clearUserDelivery: () => void;
}

const useUserDeliveryService = (language: string, isLoggedIn: boolean): UserDeliveryService => {
  const [userDeliveryLocalStorage, setUserDeliveryLocalStorage] = useLocalStorage<UserDeliveryType>(USER_DELIVERY_KEY, null);
  const [userDeliverySessionStorage, setUserDeliverySessionStorage] = useSessionStorage<UserDeliveryType>(USER_DELIVERY_KEY, null);
  const [userDeliveryIsReady, setUserDeliveryIsReady] = useState(false);

  const userDelivery = isLoggedIn && userDeliveryIsReady
    ? userDeliverySessionStorage
    : userDeliveryLocalStorage;

  const synchronizeUserDelivery = async () => {
    if (userDeliveryIsReady) {
      setUserDeliveryIsReady(false);
    }

    if (isLoggedIn && !userDeliverySessionStorage) {
      const newUserDelivery = await getDeliveryFromServerCart(language);

      if (newUserDelivery) {
        setUserDeliverySessionStorage(newUserDelivery);
      } else {
        setUserDeliverySessionStorage(userDeliveryLocalStorage);
      }
    }

    setUserDeliveryIsReady(true);
  };

  useEffect(() => {
    synchronizeUserDelivery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const updateUserDelivery = (delivery: UserDeliveryType) => {
    if (isLoggedIn) {
      setUserDeliverySessionStorage(delivery);
    } else {
      setUserDeliveryLocalStorage(delivery);
    }
  };

  const clearUserDelivery = () => {
    setUserDeliveryLocalStorage(null);
    setUserDeliverySessionStorage(null);
  };

  return {
    clearUserDelivery,
    userDelivery,
    userDeliveryIsReady,
    updateUserDelivery,
  };
};

export default useUserDeliveryService;
