import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import typography from '../../../../styles/typography';

const getStyles = (theme: Theme) => {

  return css`
    .SeoText {
      margin-top: 72px;
    }

    .SeoText__wrapper {
      position: relative;
      max-height: none;
    }

    .SeoText__content :global(* + *) {
      margin: 24px 0 0;
    }

    .SeoText__content :global(h1) {
      ${typography.h1}
    }

    .SeoText__content :global(h2) {
      ${typography.h2}
    }

    .SeoText__content :global(h3) {
      ${typography.h3}
    }

    .SeoText__content :global(h4) {
      ${typography.h4}
    }

    .SeoText__content :global(h5) {
      ${typography.h5}
    }

    .SeoText__content :global(h6) {
      ${typography.h6}
    }

    .SeoText__content :global(* + h1),
    .SeoText__content :global(* + h2),
    .SeoText__content :global(* + h3),
    .SeoText__content :global(* + h4),
    .SeoText__content :global(* + h5),
    .SeoText__content :global(* + h6) {
      margin : 40px 0 0;
    }

    .SeoText__content :global(img + h1),
    .SeoText__content :global(img + h2),
    .SeoText__content :global(img + h3),
    .SeoText__content :global(img + h4),
    .SeoText__content :global(img + h5),
    .SeoText__content :global(img + h6) {
      margin : 32px 0 0;
    }

    .SeoText__content :global(p) {
      ${typography.text}
    }

    .SeoText__content :global(h3 + p) {
      margin-top: 16px;
    }

    .SeoText__content :global(ol) {
      padding: 0;
      margin: 24px 0 0;
      list-style-position: inside;
    }

    .SeoText__content :global(ul){
      list-style: none;
      padding: 0;
      margin: 24px 0 0;
    }

    .SeoText__content :global(li) {
      ${typography.text}
    }

    .SeoText__content :global(ol > li) {
      margin: 0;
    }

    .SeoText__content :global(ul > li) {
      margin: 8px 0 0;
    }

    .SeoText__content :global(ul > li:before) {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      margin: 0 8px 1px 0;

      border: 1px solid ${theme.base.color.primary};
      border-radius: 50%;

      vertical-align: baseline;
    }

    .SeoText__content :global(img){
      display: block;
      max-width: 100%;
      max-height: 240px;
      object-fit: contain;
      margin: 40px 0 32px;
    }
  `;
};

export default getStyles;
