import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import typography from '../../../../styles/typography';

const getStyles = (theme: Theme) => {
  return css`
    .AddressManagementBar {
      display: flex;
      min-height: 72px;
      align-items: center;
      padding: 10px 34px 10px 26px;
      background: #fff;
      box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
      border-radius: ${theme.base.borderRadii.v1};
      cursor: pointer;
    }

    .AddressManagementBar__iconDelivery {
      font-size: 24px;
      color: ${theme.base.color.primary};
    }

    .AddressManagementBar__iconNY {
      font-size: 32px;
    }

    .AddressManagementBar__iconDelivery_withAnimation {
      animation: bounce 2s ease;
    }

    .AddressManagementBar__delivery {
      flex: 1 0 0;
      margin: 0 12px;
    }

    .AddressManagementBar__button {
      flex-shrink: 0;
    }

    .AddressManagementBar_medium {
      padding: 10px 18px 10px 20px;
    }

    .AddressManagementBar_medium .AddressManagementBar__delivery {
      margin-left: 12px;
    }

    .AddressManagementBar_small {
      align-items: flex-start;
      min-height: auto;
      padding: 16px 12px;
    }

    .AddressManagementBar_small .AddressManagementBar__iconDelivery {
      font-size: 17px;
      align-self: baseline;
    }

    .AddressManagementBar_small .AddressManagementBar__delivery {
      margin: 0 10px 0 12px;
      ${typography.text};
    }

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-15px);
      }
      60% {
        transform: translateY(-7px);
      }
    }
    `;
};

export default getStyles;
