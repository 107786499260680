import throttle from 'lodash/throttle';
import { Fragment, useEffect, useState } from 'react';
import IconFont from 'src/ui-kit/IconFont';
import { useTheme } from 'src/ui-kit/theme';
import { getStyles } from './ScrollUpButton.styles';
import Responsive from 'src/components/Responsive';

interface Props {
  behavior?: ScrollBehavior;
}

const ScrollUpButton = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [visible, setVisible] = useState(false);

  const handleOnPress = () => {
    const { behavior } = props;

    window.scrollTo({
      top: 0,
      behavior,
    });
  };

  useEffect(() => {
    let lastScrollTop = document.documentElement.scrollTop;

    const toggleVisible = throttle(() => {
      const windowHeight = window.innerHeight;
      const scrolled = document.documentElement.scrollTop;
      const userScrolledMoreThenOneScreen = scrolled > windowHeight;
      const userScrolledUp = !(scrolled > lastScrollTop);
      const buttonShouldBeVisible = userScrolledMoreThenOneScreen && userScrolledUp;

      setVisible(buttonShouldBeVisible);

      lastScrollTop = scrolled;
    }, 500);

    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Fragment>
      {visible && (
        <button
          className="ScrollUpButton"
          onClick={handleOnPress}
          data-marker="Scroll Up"
        >
          <IconFont
            name='arrow-up'
            size='20px'
            sbs={{
              color: 'inherit',
            }}
          />
        </button>
      )}

      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default function ScrollUpResponsive () {
  return (
    <Responsive displayOn='tabletDesktop'>
      <ScrollUpButton />
    </Responsive>
  );
}
