import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .SocialItem {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      text-decoration: none;
      border: 1px solid ${theme.base.color.gray300};
      color: ${theme.base.color.dark};
      border-radius: 50%;
      transition: 0.2s border-color ease-in-out;
      background-color: ${theme.base.color.white};
    }

    .SocialItem:hover {
      border-color: ${theme.base.color.dark};
    }
  `;
};

export default getStyles;
